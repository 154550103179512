<script setup>

import '@vuepic/vue-datepicker/dist/main.css';
import getAllClients from "@/helpers/GetAllClients";

import Container from '@/components/containers/Container.vue';
import Header from '@/components/objects/Header.vue';
import SectionPanel from "@/components/containers/SectionPanel.vue";
import RetainerListing from "@/components/pages/beta/admin/clients/RetainerListing.vue";
import Loading from '@/components/containers/Loading.vue';
import WhiteTick from '~icons/my-icons/whitetick'
import convertTimeHelper from '@/helpers/ConvertTimeHelper.js';
import staticData from "@/data/staticData";
import Fuse from 'fuse.js';
import CheveronDown from '~icons/my-icons/chevron-down';
import PlusClose from '~icons/my-icons/cross';

import Search from '~icons/my-icons/search';
import IconClose from '~icons/my-icons/cross';

import { getDatabase, onValue, ref } from "firebase/database";

</script>

<template>
    <Header />
    <div class="pb-20 bg-white flex-1 ml-[196px] relative">
        <div class="py-12">
            <Container class="w-full flex justify-between items-center">
                <h1 class="text-3xl font-bold">Clients</h1>
                <div class="flex">
                    <div @click="toggleArchived" class="border border-gray-200 rounded mr-5 flex cursor-pointer">
                        <div class="flex items-center justify-center px-3 space-x-3">
                            <div :class="showArchived ? 'bg-gray-900' : ''"
                                class="w-5 h-5 border border-gray-200 rounded group-focus:bg-gray-900 flex items-center justify-center">
                                <WhiteTick class="w-3 h-2" />
                            </div>
                            <p class="text-sm font-medium select-none">Show archived</p>
                        </div>
                    </div>
                    <div @click="toggleAddBackgroundAlert"
                        class="border border-gray-200 rounded mr-5 flex cursor-pointer">
                        <div class="flex items-center justify-center px-3 space-x-3">
                            <div :class="addBackgroundAlert ? 'bg-gray-900' : ''"
                                class="w-5 h-5 border border-gray-200 rounded group-focus:bg-gray-900 flex items-center justify-center">
                                <WhiteTick class="w-3 h-2" />
                            </div>
                            <p class="text-sm font-medium select-none">With alerts</p>
                        </div>
                    </div>
                    <!--<div @click="toggleAddBackgroundHappy" class="border border-gray-200 rounded mr-5 flex cursor-pointer">
                        <div class="flex items-center justify-center px-3 space-x-3">
                            <div :class="addBackgroundHappy ? 'bg-gray-900' : ''" class="w-5 h-5 border border-gray-200 rounded flex items-center justify-center">
                                <WhiteTick class="w-3 h-2" />
                            </div>
                            <p class="text-sm font-medium select-none">Unhappy clients</p>
                        </div>
                    </div>-->
                    <div class="relative mr-5" v-click-outside-element="close">
                        <div
                            class="flex items-center px-4 rounded border border-bd w-[250px] text-ellipsis py-2.5 h-12">
                            <p @click="showDetails" class="cursor-pointer w-[248px] truncate">{{ selectedHappinessLevel
                                ? getReadableGroupName(selectedHappinessLevel) : "All happiness levels" }}</p>
                            <CheveronDown @click="showDetails"
                                :class="showModal && 'rotate-180', selectedHappinessLevel && 'hidden'"
                                class="h-[20px] w-[20px] ml-3 cursor-pointer" />
                            <PlusClose @click="clearSelection" :class="selectedHappinessLevel ? 'block' : 'hidden'"
                                class="w-3 cursor-pointer" />
                        </div>
                        <div v-if="showModal" class="min-w-[250px] max-w-[250px] absolute left-3 top-[50px] z-[51]">
                            <div class="bg-white flex row border border-gray-900 w-full">
                                <ul class="pl-2 max-w-[480px] max-h-[204px] overflow-x-hidden overflow-y-auto">
                                    <li @click="clearSelection"
                                        :class="!selectedHappinessLevel ? 'bg-[#FF4B40] text-white hover:bg-[#FF4B40] hover:text-grey-800' : ''"
                                        class="p-2 cursor-pointer hover:bg-gray-100 active:bg-[#FF4B40] active:text-white leading-7 rounded-[4px] my-2 truncate">
                                        All happiness levels
                                    </li>
                                    <li v-for="level in ['happy-group', 'moderate', 'sad-group']"
                                        @click="filterByHappinessLevel(level)"
                                        :class="isGroupSelected(level) ? 'bg-[#FF4B40] text-white hover:bg-[#FF4B40] hover:text-grey-800' : ''"
                                        class="p-2 cursor-pointer hover:bg-gray-100 active:bg-[#FF4B40] active:text-white leading-7 rounded-[4px] my-2 truncate">
                                        {{ getReadableGroupName(level) }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="relative w-[400px] mr-4">
                        <Search class="w-6 h-6 absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none" />
                        <input type="text" :value="q" @input="q = $event.target.value"
                            class="block rounded border border-bd mr-4 w-full pl-12 py-3 pr-12"
                            placeholder="Search clients" />
                        <IconClose @click="q = ''" v-if="q.length > 0"
                            class="w-3 h-3 absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer" />
                    </div>
                    <router-link :to="{ name: 'Admin - Add new client' }" type="button"
                        class="inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
                        Add new client
                    </router-link>
                </div>
            </Container>
        </div>
        <Container class="w-full">
            <div>
                <SectionPanel :noStyles="true">
                    <Loading :isLoading="isLoading" :loadText="'Loading...'">
                        <RetainerListing :showArchived="showArchived" :retainer-data="filteredList" :searchTerm="q"
                            @parentClose="closeModal" @handleFullReset="handleFullReset" />
                    </Loading>
                </SectionPanel>
            </div>
        </Container>
    </div>
</template>

<script>

export default {
    data() {
        return {
            q: '',
            allRetainers: [],
            resultsLimit: 0,
            filterDate: {
                year: new Date().getFullYear(),
                month: new Date().getMonth()
            },
            dateFilter: {
                isOpened: false
            },
            showArchived: false,
            isLoading: true,
            editClientOpen: false,
            unHappyClientFilter: false,
            addBackgroundAlert: false,
            addBackgroundHappy: false,
            showModal: false,
            selectedHappinessLevel: [],
        }
    },
    watch: {
        '$route'(to, from) {
            this.getStats()
        }
    },
    computed: {
        filteredClientName() {
            const fuse = new Fuse(this.allRetainers, {
                keys: ['name'],
                threshold: 0.4,
                distance: 100,
                includeScore: true,
                minMatchCharLength: 1
            });

            if (this.q) {
                return fuse.search(this.q);
            }
            return this.allRetainers.map(val => ({
                item: Object.assign(val, {}),
                matches: [],
                score: 0.4
            }));
        },
        filteredList() {
            // if unhappy is checked &&  retainer.happiness == sad && very sad show those retainers
            const { resultsLimit } = this;
            const arr = this.filteredClientName.map(result => result.item)
                .filter((item) => {
                    if(!this.selectedHappinessLevel) return true;
                    return this.selectedHappinessLevel.includes(item.happiness)
                }).filter((item) => {

                    if (this.addBackgroundAlert) {

                        let previousMeetingUnix = 0;
                        let futureMeetingUnix = 0;
                        if (item.meetings && item.meetings.length > 0) {

                            const dateArrays = item.meetings?.map(a => {
                                return new Date(a.date).setHours(a.time.hours, a.time.minutes, a.time.seconds);
                            });
                            const allPreviousMeetings = dateArrays.filter(a => a < Math.floor(new Date().getTime())).sort((a, b) => b - a);
                            if (allPreviousMeetings.length > 0) {
                                previousMeetingUnix = allPreviousMeetings[0];
                            }
                            const allFutureMeetings = dateArrays.filter(a => a > Math.floor(new Date().getTime())).sort((a, b) => a - b);
                            if (allFutureMeetings.length > 0) {
                                futureMeetingUnix = allFutureMeetings[0]
                            }
                        }


                        if (futureMeetingUnix == 0) {
                            return true
                        }
                        if (previousMeetingUnix > 0 && futureMeetingUnix > 0) {
                            // Calculate if frequency target is met
                            const thisDateFrequencies = staticData.dateFrequencies.find(a => a.value === item.meetingFrequency).calculateDayValue;

                            const daysDifference = Math.ceil((futureMeetingUnix - previousMeetingUnix) / (1000 * 3600 * 24))

                            if (thisDateFrequencies < daysDifference) {
                                return true;
                            }
                        }
                        if ((convertTimeHelper(item.lastQuoteEditDate, true) > 365 ? true : false && new Date().valueOf() > new Date(formattedRetainerData?.snoozeQuoteRateAlert || 0).valueOf())) {
                            return true
                        }
                    } else {
                        return true
                    }
                }).sort((a, b) => a?.name?.localeCompare(b?.name));
            return resultsLimit > 0 ? arr.slice(0, resultsLimit) : arr;
        },
        dateLabel() {
            const { filterDate } = this;
            var options = { month: 'long', year: 'numeric' };
            var date = new Date(filterDate.year, filterDate.month, 1);
            return date.toLocaleString('en-GB', options);
        }
    },
    mounted() {
        $('body').removeClass('overflow-hidden')
        this.getStats();
        document.addEventListener('click', event => {
            if (event.target.closest('.js-dropdown-calendar') === null) {
                this.dateFilter.isOpened = false;
            }
        });
        if (this.$route.params.happinessLevel) {
            if (this.$route.params.happinessLevel === 'happy-group') {
                this.selectedHappinessLevel = ['v-happy', 'happy'];
            } else if (this.$route.params.happinessLevel === 'sad-group') {
                this.selectedHappinessLevel = ['sad', 'v-sad'];
            } else {
                this.selectedHappinessLevel = [this.$route.params.happinessLevel];
            }
        } else {
            this.selectedHappinessLevel = null;
        }
    },
    methods: {
        handleFullReset() {
            this.q = '';
            this.showArchived = false;
            this.addBackgroundAlert = false;
            this.addBackgroundHappy = false;
        },
        handleDateUpdate(e) {
            this.filterDate = e;
            this.allRetainers = [];
            this.isLoading = true;
            this.dateFilter.isOpened = false;
            this.getStats();
        },
        toggleArchived() {
            this.showArchived = !this.showArchived;
        },
        convertToHours(seconds) {
            if (!seconds) {
                return 0
            }
            var timeInt = parseInt(seconds)
            var hours = (timeInt / 3600)
            return (Math.round((hours + Number.EPSILON) * 100) / 100);
        },
        async getStats() {
            const db = getDatabase();
            const clientsRef = ref(db, `/clients`);
            onValue(clientsRef, (snapshot) => {
                if (snapshot.exists()) {
                    const clientsObject = snapshot.val()

                    this.allRetainers = Object.keys(clientsObject).map(key => {
                        return {
                            ...clientsObject[key],
                            clientId: key
                        }
                    });
                    this.isLoading = false;
                } else {
                    console.log("No data available");
                    return {}
                }
            })
        },
        closeModal(reloadData) {
            this.editClientOpen = false;
            if (reloadData) {
                this.getStats();
            }
        },
        // showModal() {
        //     this.showModal = !this.showModal
        // },
        close() {
            this.showModal = false;
        },
        showDetails() {
            this.showModal = !this.showModal;
        },
        filterByHappinessLevel(level) {
            if (level === 'All happiness levels') {
                this.selectedHappinessLevel = null;
            } else if (level === 'happy-group') {
                this.selectedHappinessLevel = ['v-happy', 'happy'];
            } else if (level === 'sad-group') {
                this.selectedHappinessLevel = ['sad', 'v-sad'];
            } else {
                this.selectedHappinessLevel = [level];
            }
        },
        isGroupSelected(group) {
            if (this.selectedHappinessLevel) {
                if (group === 'happy-group') {
                    return this.selectedHappinessLevel.includes('v-happy') || this.selectedHappinessLevel.includes('happy');
                } else if (group === 'sad-group') {
                    return this.selectedHappinessLevel.includes('sad') || this.selectedHappinessLevel.includes('v-sad');
                } else {
                    return this.selectedHappinessLevel.includes(group);
                }
            }
            return false;
        },
        getReadableGroupName(group) {
            if (Array.isArray(group)) {
                if (group.includes('v-happy') || group.includes('happy')) {
                    return 'Happy';
                } else if (group.includes('sad') || group.includes('v-sad')) {
                    return 'Sad';
                } else if (group.includes('moderate')) {
                    return 'Moderate';
                }
            } else {
                if (group === 'happy-group') {
                    return 'Happy';
                } else if (group === 'moderate') {
                    return 'Moderate';
                } else if (group === 'sad-group') {
                    return 'Sad';
                } else {
                    return group;
                }
            }
        },
        clearSelection() {
            // Clear the selected team
            this.selectedHappinessLevel = null;
        },
        toggleAddBackgroundAlert() {
            this.addBackgroundAlert = !this.addBackgroundAlert
        },
        // toggleAddBackgroundHappy() {
        //     this.addBackgroundHappy = !this.addBackgroundHappy
        // },
        // onHappinessLevelChange(event) {
        //     this.selectedHappinessLevel = event.target.value;
        // },
    }
}
</script>

<style lang="scss">
.dp__selection_grid_header {
    padding-bottom: 16px;
}

.dp__overlay_cell_disabled {
    opacity: 0.3;
    background-color: transparent;

    &:hover {
        background-color: transparent;
    }

    &.dp__overlay_cell_active {
        color: theme('colors.grey-900');

        &:hover {
            background-color: transparent;
        }
    }
}

.dp__overlay_cell_active {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: theme('colors.grey-900');
    border-radius: 999px;
    font-weight: 700;
}

.dp__overlay {
    .dp__overlay_cell {
        font-weight: 500;
        font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
    }

    .dp__pointer {
        font-weight: bold;
        font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
    }
}
</style>

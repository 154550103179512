<script setup>

    import { getAuth } from "firebase/auth";
    import { getDatabase, ref, get, update, onValue } from "firebase/database";
    import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
    import axios from 'axios';
    import store from '@/store';

    import Container from '@/components/containers/Container.vue';
    import Header from '@/components/objects/Header.vue';
    import Notification from '@/components/objects/Notification.vue';
    import DashboardEditItem from '@/components/DashboardEditItem.vue';
    import Loading from '@/components/containers/Loading.vue';
    import ArrowLeft from '~icons/my-icons/arrow-left';
    import PermissionsValidation from '@/components/containers/PermissionsValidation.vue';
    import servicesData from '@/data/servicesData.js';

	import LogAuditLog from "@/helpers/logAuditLog";
</script>

<template>
    <div class="relative w-full">
        <PermissionsValidation :requiredRoles="['admin']">
            <div class="py-9 px-8 border-b border-solid border-bd">
                <Container>
                    <div class="w-full flex justify-between items-center text-3xl font-bold">
                        <button @click="$router.go(-1)" class="font-bold">
                            <span class="inline-flex items-center">
                                <ArrowLeft class="mr-8 text-xl" />
                                <span>Retainer Settings</span>
                            </span>
                        </button>
                    </div>
                </Container>
            </div>
            <div class="pt-4 pb-20 relative">
                <div class="max-w-[480px] ml-auto mr-auto">
                    <Notification :notificationType="notification" />
                </div>
                <Container>
                    <div class="max-w-[840px] mt-16 mx-auto border border-solid border-bd rounded-lg bg-white u-content-wrapped">
                        <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                            <div class="px-12 py-10">
                                <div class="my-4">
                                    <DashboardEditItem 
                                        name="logo"
                                        label="Logo"
                                        inputType="file"
                                        v-model:val="retainerDetails.logo"
                                        :initValue="retainerDetails.logo"
                                    />
                                </div> 
                                <div class="my-4">
                                    <DashboardEditItem 
                                        name="name"
                                        label="Client"
                                        inputType="team"
                                        v-model:val="retainerDetails.name"
                                        :initValue="retainerDetails.name"
                                    />
                                </div> 
                                <div class="my-4">
                                    <DashboardEditItem 
                                        name="url"
                                        label="URL"
                                        inputType="url"
                                        v-model:val="retainerDetails.url"
                                        :initValue="retainerDetails.url"
                                    />
                                </div> 
                                <div class="my-4">
                                    <DashboardEditItem 
                                        name="startDate"
                                        label="Start date"
                                        inputType="date"
                                        v-model:val="retainerDetails.startDate"
                                        :initValue="retainerDetails.startDate"
                                    />
                                </div> 
                                <div class="my-4">
                                    <DashboardEditItem 
                                        name="contractExpiry"
                                        label="Contract expiry"
                                        inputType="date"
                                        v-model:val="retainerDetails.contractExpiry"
                                        :initValue="retainerDetails.contractExpiry"
                                    />
                                </div> 
                                <div class="my-4">
                                    <DashboardEditItem 
                                        name="monthlyValue"
                                        label="Monthly value"
                                        v-model:val="retainerDetails.value"
                                        :initValue="retainerDetails.value"
                                    />
                                </div> 
                                <div class="my-4">
                                    <DashboardEditItem 
                                        name="description"
                                        label="Description"
                                        inputType="textarea"
                                        v-model:val="retainerDetails.description"
                                        :initValue="retainerDetails.description"
                                    />
                                </div> 
                                <div class="my-4">
                                    <div class="border-t my-6"></div>
                                    <h4 class="my-6 font-semibold text-grey-900">Retainer Plans</h4>
                                    <div class="p-6 my-4 bg-blue-50" :key="plan" v-for="(plan, index) in retainerDetails.plans">
                                        <div class="grid grid-cols-3 gap-3">
                                            <DashboardEditItem name="rolloverFrom"
                                                label="Rollover Current Plan From"
                                                inputType="month"
                                                type="column"
                                                v-model:val="plan.rolloverFrom"
                                                :initValue="plan.rolloverFrom" />
                                            <DashboardEditItem name="monthlyHours"
                                                label="Monthly Hours"
                                                type="column"
                                                v-model:val="plan.monthlyHours"
                                                :initValue="plan.monthlyHours" />
                                            <DashboardEditItem name="rolloverHours"
                                                label="Max Rollover Hours"
                                                type="column"
                                                v-model:val="plan.rolloverHours"
                                                :initValue="plan.rolloverHours" />
                                        </div>
                                        <div>
                                            <label for="" class="min-w-[125px] grow-0 shrink basis-0 text-xs text-grey-300 mr-3 inline-block mb-2">Comment</label>
                                            <textarea v-model="plan.comment" class="block w-full min-h-[128px] p-4" id=""></textarea>
                                        </div>

                                        <button class="mt-6 bg-grey-50 border border-grey-200  rounded px-3 py-1.5 text-xs flex items-center justify-center"
                                                    v-on:click="removePlan(index)">Remove Retainer Plan</button>
                                    </div>
                                    <button class="bg-grey-50 border border-grey-200 rounded px-3 py-1.5 text-xs flex items-center justify-center"
                                            v-on:click="addPlan">Add Retainer Plan</button>
                                </div>
                                <div class="border-t my-6"></div>
                                <h4 class="mt-6 mb-2 font-semibold text-grey-900">Retainer Services</h4>
                                <p class="text-xs mb-6">These values will override the default retainer services rates set on the application level for this particular retainer.</p>
                                <div class="my-4 relative inline-flex items-center cursor-pointer ">
                                    <DashboardEditItem 
											name="showRetainerServices"
											label="Show Retainer Services"
                                            inputType="checkbox"
											v-model:val="retainerDetails.showRetainerServices"
											:initValue="retainerDetails.showRetainerServices"
										/>
                                </div>
                                <!-- <div class="my-4">
                                    <DashboardEditItem 
                                        name="retainerRate"
                                        label="Retainer rate <br>(per hour)"
                                        v-model:val="retainerDetails.services.retainer.cost"
                                        :initValue="retainerDetails.services.retainer.cost"
                                    />
                                </div> -->
                                <div class="text-right flex justify-end items-center mt-4">
                                    <button @click="$router.go(-1)" class="inline-block text-blue-500 mr-6">Cancel</button>
                                    <button 
                                        type="button" 
                                        class="transition-all py-3 px-6 bg-orange-500 rounded text-white disabled:text-grey-300 disabled:bg-grey-100"
                                        @click="updateRetainerDetails()"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Loading>
                    </div>
                </Container>
            </div>
        </PermissionsValidation>
    </div>
</template>

<script>

  export default {
    props: [],
    data() {
      return {
          isSubmitting: false,
          notification: "",
          retainerDetail: {},
          editRetainerDetails: {}
      }
    },
    mounted() { 
       this.getRetainerData()
    },
    computed: {
        retainerDetails() {
			const serviceData = servicesData;
            let retainerData = store.getters.getRetainerDetail ? store.getters.getRetainerDetail : this.retainerDetail;
            if(!retainerData.showRetainerServices) {
                retainerData.showRetainerServices = false;
            }
            const defaultServicesRates = store.getters.getAppSettings.services;
            const setServicesRates = retainerData.services;
            let formattedData = serviceData?.map(item => {
                let obj = {
                    [item.serviceId]: {
                        cost: defaultServicesRates[item.serviceId],
                        hours: 0
                    }
                };
                for(const service in setServicesRates) {
                    if(item.serviceId === service) {
                        obj[item.serviceId].cost = setServicesRates[service] ? setServicesRates[service].cost : defaultServicesRates[service];
                        obj[item.serviceId].hours = setServicesRates[service] && setServicesRates[service].hours ? setServicesRates[service].hours : 0;
                        setServicesRates[service].occurance ? obj[item.serviceId].occurance = setServicesRates[service].occurance : '';
                        setServicesRates[service].date ? obj[item.serviceId].date = setServicesRates[service].date : '';
                    } 
                }
                return obj
            });
            formattedData = Object.assign({}, ...formattedData );
            retainerData.services = formattedData;
            this.retainerDetail.services = formattedData;
            return retainerData
        }
    },
    methods: {
        getRetainerData() {
            this.retainerDetail = store.getters.getRetainerDetails;
        },
        async updateRetainerDetails() {

            const db = getDatabase();
            let retainerData = {...this.retainerDetails}

            this.isSubmitting = true;

            if(typeof retainerData.logo?.name == 'string') {
                const file = retainerData.logo;
                const fullPath = `retainer-logo/${this.$route.params.retainerId}/${file.name}`;
                // Upload to storage
                const storage = getStorage();
                const storageReference = storageRef(storage, fullPath);
                const snapshot = await uploadBytes(storageReference, file);
                const imageUrl = await getDownloadURL(snapshot.ref);
                retainerData.logo = imageUrl;
                this.retainerDetails.logo = imageUrl;
            }

            const updates = {};
            updates[`/retainers/${this.$route.params.retainerId}`] = retainerData;
            update(ref(db), updates).then(res => {
                store.commit('updateRetainerDetails', retainerData);
                this.notification = 'Success';
                this.isSubmitting = false;
                this.runRetainerSync();
                window.scrollTo(0,0)
            }).catch(err => {
                console.log(err);
                this.isSubmitting = false;
            });

        },
        runRetainerSync() {
            const auth = getAuth();
            auth.onAuthStateChanged(user => {
                if (user) {
                    const currentToken = auth.currentUser.accessToken;

                    axios.get(`${this.$root.apiBaseUrl}/retainer/sync-rollover/${this.$route.params.retainerId}`, {
                        headers: {
                            'authorization': currentToken,
                            'timezone': this.$root.apiTimezone
                        },
                    }).then(res => {
                        LogAuditLog({
                                type: "Retainer",
                                object: "Sync",
                                action: "run",
                                data: {
                                        user: user.uid,
                                        retainerId: this.$route.params.retainerId,
                                }
                        })

                    }).catch(err => {
                        console.log(err)
                    });
                }
            });
        },
        addPlan() {
            this.retainerDetail.plans = this.retainerDetail.plans || [];
            this.retainerDetail.plans.push({});
        },
        removePlan(i) {
            this.retainerDetail.plans = this.retainerDetail.plans || [];
            this.retainerDetail.plans.splice(i, 1);
        }
    }
  }
</script>

<style lang="scss">
    
</style>

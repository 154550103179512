<script setup>

import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, query, orderByKey, equalTo, get } from "firebase/database";
import axios from 'axios';
import Multiselect from '@vueform/multiselect'

import store from '@/store';
import Container from '@/components/containers/Container.vue';
import Header from '@/components/objects/Header.vue';
import AuthValidation from '@/components/containers/AuthValidation.vue';
import PermissionsValidation from '@/components/containers/PermissionsValidation.vue';
import PermissionsHelpers from '@/helpers/PermissionHelpers';
import initCurrentUserSession from '@/helpers/InitCurrentUserSession';
import initAllProjects from '@/helpers/InitAllProjects';
import Notification from '@/components/objects/Notification.vue';
import TeamValueToLabel from '@/helpers/TeamValueToLabel';
import ProjectValueToLabel from '@/helpers/ProjectValueToLabel';
import RetainerDropdown from '@/components/objects/RetainerDropdown.vue';

import { authTokenUpdate } from '@/helpers/AuthTokenUpdate';

import IconSync from '~icons/my-icons/sync';
import ArrowRight from '~icons/my-icons/arrow-right';

import servicesData from '@/data/servicesData.js';

	import LogAuditLog from "@/helpers/logAuditLog";
</script>

<template>
	<div class="flex-1 h-full ml-[196px] relative">
		<PermissionsValidation :requiredRoles="['admin', 'webcoda-user', 'client-admin', 'client-user']">
			<div v-if="!editMode">
				<div class="py-5 bg-grey-50 border-b border-solid border-b-gray-200">
					<Container class="w-full flex justify-between items-center">
						{{ retainers }}
						<PermissionsValidation v-if="internalWebcodaUserRole || assignedRetainers.length > 1" :noMessage="'true'"
							class="w-full max-w-[38rem]">
							<div class="flex items-center group" v-click-outside-element="close">
								<RetainerDropdown :active="showModal" :onClick="showDetails" :src="logo" :activeLabel="retainerName"
									:items="internalWebcodaUserRole ? allRetainersList : assignedRetainers"
									:actRetainer="$route.params.retainerId" @handleActiveRetainer="handleUpdateActiveRetainer">
								</RetainerDropdown>
							</div>
						</PermissionsValidation>
						<div v-else class="flex items-center pointer-events-none">
							<img v-if="logo && !logo.includes('undefined')" :src="logo" :alt="retainerData?.name"
								class="inline-block max-w-[160px] max-h-[32px] mix-blend-multiply mr-5">
							<p class="font-bold cursor-pointer max-w-[384px] truncate">{{ retainerName || "Please select a retainer"
								}}</p>
						</div>
						<!--<PermissionsValidation :noMessage="'true'" v-else>
							<h1 class="text-3xl font-bold">
								<span v-if="!isInitLoading">
									<img v-if="retainerData.logo" :src="retainerData.logo" :alt="retainerData.name" class="max-w-[160px] max-h-[48px]" />
									<span v-else>{{TeamValueToLabel(retainerData.name) ? TeamValueToLabel(retainerData.name) : this.$route.params.retainerId ? activeRetainerLabel : 'No retainer assigned to user.'}}</span>
								</span>
							</h1>
						</PermissionsValidation>
						<div class="flex items-center">
							<PermissionsValidation :requiredRoles="['admin']">
								<router-link v-if="this.$route.params.retainerId" :to="{ name: 'Send mail', params: { retainerId: this.$route.params.retainerId } }" class="mr-2 inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
									Send mailasdf
								</router-link>
							</PermissionsValidation>
							<router-link v-if="this.$route.params.retainerId && mostCurrentApprovedMonth.length > 0" :to="{ name: 'Beta Dashboard Reports Listing', params: { date: mostCurrentApprovedMonth }}" class="inline-flex items-center relative block bg-orange-500 rounded text-white py-3 px-6">
								View current report
								<ArrowRight class="ml-3 text-white" />
							</router-link>
						</div>-->
					</Container>
				</div>
				<Notification :type="'relative'" :notificationType="notifications" />
				<div v-if="$route.meta.fullWidth">
					<router-view></router-view>
				</div>
				<div v-else>
					<Container>
						<router-view></router-view>
					</Container>
				</div>
			</div>
		</PermissionsValidation>
	</div>
</template>

<script>
export default {
	data() {
		return {
			assignedRetainers: [],
			allRetainersList: [],
			editMode: false,
			notifications: {},
			tileData: [],
			retainerData: null,
			snapshotLoading: true,
			retainerUsers: [],
			isInitLoading: true,
			currentUserRole: store.getters.getUserState.permission,
			isLoading: false,
			showModal: false,
			logo: '',
		}
	},
	watch: {
		'$route'(to, from) {
			if (to?.params?.retainerId) {
				const currentUser = store.getters.getUserState;

				this.getRetainerData();
				this.getStatistics();
				this.getClientData();
				// if (!(store.getters.getUserState.retainer?.find(e => e == this.$route.params.retainerId) || currentUser.permission === 'admin')) {
				// 	this.$router.push({params: {retainerId: store.getters.getUserState.retainer?.[0] || 0}})
				// }
			}
		}
	},
	created() {
		authTokenUpdate((claims) => {
			this.currentUser = claims;
		})();
	},
	computed: {
		internalWebcodaUserRole() {
			return this.currentUserRole === 'admin' || this.currentUserRole === 'webcoda-user'
		},
		activeRetainerLabel() {
			return ProjectValueToLabel(this.$route.params.retainerId)
		},
		isWebcodaAdmin() {
			const currentUser = store.getters.getUserState;
			const viewAsClient = store.getters.getViewAsClient;
			if (viewAsClient) {
				return false
			}
			return currentUser.permission === 'admin'
		},
		filterSnapshotMonths() {
			let { tileData, retainerData } = this;

			// Filter out by tasks count				
			return tileData.filter(item => item.listData.filter(l => l.itemName === 'Tasks')[0].itemValue > 0);
		},
		mostCurrentApprovedMonth() {
			const filteredData = this.filterSnapshotMonths;
			if (filteredData && filteredData.length > 0) {
				filteredData.sort((a, b) => {
					return new Date(b.title) - new Date(a.title)
				});
				return filteredData[0].title;
			}
			return '';
		},
		formatRetainerUrls() {
			if (this.retainerData?.url) {
				const urlArr = this.retainerData?.url?.replace(/\r?\n|\r/gm, '').split(',');
				return urlArr.filter(n => /\S/.test(n));
			}
			return []
		},
		formatServicesData() {
			const serviceData = servicesData;
			const { retainerData } = this;
			const retainerServicesData = retainerData.services;
			const defaultServicesRates = store.getters.getAppSettings.services;

			const formattedData = serviceData?.map(item => {
				let isServiceActive = false;
				item.cost = defaultServicesRates[item.serviceId];
				item.hours = 0;
				for (const service in retainerServicesData) {
					if (item.serviceId === service) {
						isServiceActive = retainerServicesData[service].hours && Number(retainerServicesData[service].hours) > 0 ? true : false;
						item.cost = retainerServicesData[service] ? retainerServicesData[service].cost : defaultServicesRates[service];
						item.hours = retainerServicesData[service] && retainerServicesData[service].hours ? retainerServicesData[service].hours : 0;
						item.occurance = retainerServicesData[service].occurance ? retainerServicesData[service].occurance : '';
						retainerServicesData[service].date ? item.date = retainerServicesData[service].date : '';
					}
				}
				return {
					...item,
					activeService: isServiceActive
				}
			});
			return formattedData;
		},
		currentRetainerClient() {
			const retainerClient = (this.internalWebcodaUserRole ? this.allRetainersList : this.assignedRetainers).find(value => value.value == this.$route.params.retainerId)

			return {
				...retainerClient,
				...this.retainerData,
			}
		},
		retainerName() {
			return this.currentRetainerClient?.label
		},
		currentRetainerLogo() {
			return this?.retainerData?.logo
		}
	},
	async mounted() {
		await initCurrentUserSession(this);

		
		authTokenUpdate((claims) => {
			this.currentUser = claims;
			})();
			
		const currentUser = store.getters.getUserState;
		await this.getClientData();
		
		// if retainerId that is passed is 0, then redirect to the first retainer
		if (this.$route.params.retainerId === '0') {
			this.$router.push({ params: { retainerId: this.assignedRetainers?.[0]?.value || 0 } });
		}
	},
	methods: {
		async getClientData() {
			const activeClient = this.$route.params.clientId;

			const db = getDatabase();
			const clientRef = ref(db, `/clients/${activeClient}`);
			console.log('activeClient', activeClient)
			const retainersRef = ref(db, `/retainers/${this.$route.params.retainerId}`);

			onValue(retainersRef, snapshot => {
				this.isInitLoading = true;
				this.retainerData = snapshot.val() ? snapshot.val() : {};
			});

			return await new Promise(res => {
				onValue(clientRef, async snapshot => {

					const data = snapshot.val()

					// if (!(data?.retainers?.[this?.$route?.params?.retainerId] || currentUser.permission === 'admin')) {
					// 	this.$router.push({params: {retainerId: store.getters.getUserState.retainer?.[0] || 0}})
					// }
					this.logo = data?.logo;
					if ((this.internalWebcodaUserRole || store.getters.getUserState.client) || store.getters.getViewAsClient) {
						await initAllProjects();
						console.log('store.getters.getAllProjects', store.getters.getAllProjects)
						const retainers = store.getters.getAllProjects.map(item => {
							return {
								label: item.projectName.trim(" "),
								value: item.id
							}
						});
						const sortedArray = () => {
							function compare(a, b) {
								if (a.label < b.label)
									return -1;
								if (a.label > b.label)
									return 1;
								return 0;
							}
							return retainers.sort(compare);
						}

						if (this.isWebcodaAdmin || store.getters.getViewAsClient) {
							this.allRetainersList = sortedArray();
						} else {
							this.assignedRetainers = sortedArray().filter(item =>
							store.getters.getAllProjects.some(project => project.id === item.value)
							);
						}
					}
					if (this.$route.params.retainerId) {
						this.getRetainerData();
						this.getStatistics();
					} else {
						this.tileData = [];
						this.snapshotLoading = false;
					}

					res(snapshot)
				})
			})
		},
		getRetainerData() {
			const userRetainer = this.$route.params.retainerId;
			// authTokenUpdate((claims) => {
			// 	this.currentUser = claims;
			// })();
			const db = getDatabase();
			const retainersRef = ref(db, `/retainers/${userRetainer}`);
			const retainerUsersRef = ref(db, `/retainerUsers/${userRetainer}/`);

			if (!(store.getters.getUserState.retainer?.find(e => e == userRetainer) || (store.getters.getUserState.permission === 'admin' || store.getters.getUserState.permission === 'webcoda-user'))) {
				return
			}

			onValue(retainerUsersRef, snapshot => {
				if (snapshot.val()) {
					let formattedData = Object.keys(snapshot.val()).map((key) => {
						return {
							uid: key,
							...snapshot.val()[key]
						}
					});
					let clientUserArr = formattedData.filter(user => {
						return user.permission !== 'admin' && user.permission !== 'webcoda-user'
					});
					clientUserArr.forEach((item, index) => {
						if (item.permission === 'client-admin') {
							clientUserArr.splice(index, 1);
							clientUserArr.unshift(item);
						}
					});
					let webcodaUserArr = formattedData.filter(user => {
						return user.permission == 'admin' || user.permission == 'webcoda-user'
					});
					this.retainerUsers = clientUserArr.map(user => {
						return {
							uid: user.uid,
							userSettings: { ...user }
						}
					});
				} else {
					this.retainerUsers = [];
				}
			});
		},
		syncProjects() {

				var filter = [];
				var today = new Date();
				var d;
				
				for(var i = 11; i >= 0; i -= 1) {
					d = new Date(today.getFullYear(), today.getMonth() - i, 1);
					filter.push({
						month: d.getMonth() + 1,
						year: d.getFullYear()
					});
				}
				// authTokenUpdate((claims) => {
				// 	this.currentUser = claims;
				// })();
				const auth = getAuth();
				auth.onAuthStateChanged(user => {
					if(user) {
						const currentToken = auth.currentUser.accessToken;
						axios.get(`${this.$root.apiBaseUrl}/retainer/sync-rollover/${this.$route.params.retainerId}`, {
								headers: {
										'authorization': currentToken,
										'timezone': this.$root.apiTimezone
								},
						}).then(res => {
								LogAuditLog({
										type: "Retainer",
										object: "Sync",
										action: "run",
										data: {
												user: user.uid,
												retainerId: this.$route.params.retainerId,
										}
								})
								axios({
										method: 'post',
										url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
										data: filter,
										headers: {
												'authorization': currentToken,
												'timezone': this.$root.apiTimezone
										},
										params: {
											clientView: PermissionsHelpers.isWebcodaAdmin() ? 'false' : 'true'
										}
								}).then(res => {
										this.tileData = res.data;
										this.snapshotLoading = false;
										this.notifications = 'SuccessSync';
										setTimeout(() => {
												this.notifications = '';
										}, 3600)
								}).catch(err => {
										console.log(err);
								});

					}).catch(err => {
						console.log(err)
					});
				}
			});


		},
		getStatistics() {
			var filter = [];
			var today = new Date();
			var d;

			for (var i = 11; i >= 0; i -= 1) {
				d = new Date(today.getFullYear(), today.getMonth() - i, 1);
				filter.push({
					month: d.getMonth() + 1,
					year: d.getFullYear()
				});
			}

			const auth = getAuth();
			auth.onAuthStateChanged(user => {
				if (user) {
					const currentToken = auth.currentUser.accessToken;

					axios({
						method: 'post',
						url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
						data: filter,
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						},
						params: {
							clientView: PermissionsHelpers.isWebcodaAdmin() ? 'false' : 'true'
						}
					}).then(res => {
						this.tileData = res.data;
						this.snapshotLoading = false;
					}).catch(err => {
						console.log(err);
					});

				}
			});

		},
		async handleUpdateActiveRetainer(retainerId, notHandler) {
			this.snapshotLoading = true;

			const db = getDatabase();

			const clientsRef = ref(db, '/clients');

			const clientsSnapshot = await get(clientsRef);
			const clientsData = clientsSnapshot.val();

			for (const clientId in clientsData) {
				const clientData = clientsData[clientId];

				if (clientData.retainers && clientData.retainers[retainerId]) {
					this.activeClient = clientData;

					if (!notHandler) {
						this.$router.replace({ params: { clientId: clientId, retainerId: retainerId } });
					}

					return;
				}
			}

			console.error(`No client has retainer ${retainerId}`);
		},
		dateLabel(dateStr) {
			var options = { month: 'long', year: 'numeric' };
			var thisDate = new Date(dateStr);
			return thisDate.toLocaleString('en-GB', options);
		},
		showDetails() {
			this.showModal = !this.showModal;
		},
		close() {
			this.showModal = false
		}
	}
}
</script>

<style lang="scss">
.multiselect {
	$this: &;

	&--retainerToggle {
		white-space: nowrap;
		min-height: 60px;

		#{$this}-search {
			font-size: 1.5rem;
		}

		#{$this}-single-label {
			margin-left: 3px;
		}

		#{$this}-dropdown {
			height: 30rem;
			border-right: 0;
		}

		#{$this}-options {
			display: block !important;
		}

		#{$this}-option {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
</style>
<script setup>

    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import "vue3-circle-progress/dist/circle-progress.css";
    import CircleProgress from "vue3-circle-progress";


    import Container from '@/components/containers/Container.vue';
    import Header from '@/components/objects/Header.vue';
    import store from "@/store";
    import SectionPanel from "@/components/containers/SectionPanel.vue";
    import DataBar from "@/components/DataBar.vue";
    import UserCard from "@/components/UserCard.vue";
    import Loading from '@/components/containers/Loading.vue';
    import IconHappinessHappy from '~icons/my-icons/happiness-happy';
    import IconHappinessSad from '~icons/my-icons/happiness-sad';
    import IconHappinessModerate from '~icons/my-icons/happiness-moderate';
    import HappinessRenderer from "@/components/objects/HappinessRenderer.vue";
    import IconAlert from '~icons/my-icons/alert';
    import IconAlertSm from '~icons/my-icons/alertsm';
    import GreyHappy from '~icons/my-icons/grey-happy';

    import { getDatabase, get, child, ref, onValue, update } from "firebase/database";
    
	import { ClientAlertCounter } from '@/helpers/AlertCounter';
	import RetainersNeedAttention from '@/helpers/RetainersNeedAttention';

    import { getAuth } from "firebase/auth";
    import axios from "axios";
    
    import { Skeletor } from "vue-skeletor";

    import ChevronDown from '~icons/my-icons/chevron-down';

	import { AlertTotal } from '@/helpers/AlertCounter';

	import yearMonthFormat from '@/helpers/YearMonthFormat';
</script>

<template>
    <div class="!pl-[212px]">
        <div class="py-9 bg-white border-b border-solid border-bd">
            <Container class="w-full flex flex-col gap-4 sm:flex-row justify-between items-center">
                <h1 class="text-3xl font-bold">Admin Dashboard</h1>
                <div class="relative js-dropdown-calendar" ref="dropdownCalendar">
                    <button @click="dateFilter.isOpened = !dateFilter.isOpened" :class="[!dateFilter.isOpened ? 'rounded' : 'rounded-tl rounded-tr border-b-transparent']" class="min-h-[46px] block px-4 py-2.5 w-[296px] relative text-left border border-grey-900">
                        <span v-if="dateFilter.filterActiveType === 'select'">{{dateFilter.selectedValue > 0 ? `Last ${parseInt(dateFilter.selectedValue) + 1} days`: ''}}</span>
                        <span v-if="dateFilter.filterActiveType === 'dateRange'">{{`${convertDateFormat(dateFilter.filterDate[0])} - ${convertDateFormat(dateFilter.filterDate[1])}`}}</span>
                        <ChevronDown :class="{'-rotate-180': dateFilter.isOpened}" class="transition transition-500 absolute right-2 top-1/2 -translate-y-1/2 text-lg" />
                    </button>
                    <div v-if="dateFilter.isOpened" class="w-full absolute bg-white p-4 top-[calc(100%-1px)] right-0 border border-grey-900 border-t-0 z-[2] rounded-bl rounded-br">
                        <div class="flex rounded-full border-2 border-grey-900 bg-grey-900 mb-5">
                            <div class="flex-1">
                                <label class="block">
                                    <input v-model="dateFilter.displayType" type="radio" value="select" name="dateFilterType" class="sr-only peer" />
                                    <span class="block text-center py-2 px-4 text-sm cursor-pointer text-white peer-checked:bg-white peer-checked:text-grey-900 rounded-full font-medium">Select</span>
                                </label>
                            </div>
                            <div class="flex-1">
                                <label class="block">
                                    <input v-model="dateFilter.displayType" type="radio" value="dateRange" name="dateFilterType" class="sr-only peer"/>
                                    <span class="block text-center py-2 px-4 text-sm cursor-pointer text-white peer-checked:bg-white peer-checked:text-grey-900 rounded-full font-medium">Date range</span>
                                </label>
                            </div>
                        </div>
                        <div v-if="dateFilter.displayType == 'select'">
                            <div>
                                <label class="block mb-2">
                                    <input v-model="dateFilter.selectedValue" @change="handleSelectDateFilter($event)" type="radio" value="6" name="dateFilter" class="sr-only peer" />
                                    <span class="peer-checked:bg-grey-50 peer-checked:text-grey-600 peer-checked:cursor-auto peer-checked:border peer-checked:border-bd border-2 rounded-[6px] block text-center py-2 px-4 text-sm border-grey-900 cursor-pointer">Last 7 days</span>
                                </label>
                            </div>
                            <div>
                                <label class="block mb-2">
                                    <input v-model="dateFilter.selectedValue" @change="handleSelectDateFilter($event)" type="radio" value="13" name="dateFilter" class="sr-only peer" />
                                    <span class="peer-checked:bg-grey-50 peer-checked:text-grey-600 peer-checked:cursor-auto peer-checked:border peer-checked:border-bd border-2 rounded-[6px] block text-center py-2 px-4 text-sm border-grey-900 cursor-pointer">Last 14 days</span>
                                </label>
                            </div>
                            <div>
                            <label class="block mb-2">
                                    <input v-model="dateFilter.selectedValue" @change="handleSelectDateFilter($event)" type="radio" value="29" name="dateFilter" class="sr-only peer" />
                                    <span class="peer-checked:bg-grey-50 peer-checked:text-grey-600 peer-checked:cursor-auto peer-checked:border peer-checked:border-bd border-2 rounded-[6px] block text-center py-2 px-4 text-sm border-grey-900 cursor-pointer">Last 30 days</span>
                                </label>
                            </div>
                        </div>
                        <div v-if="dateFilter.displayType == 'dateRange'">
                            <Datepicker
                                :menuClassName="'c-datepicker--dropdown'"
                                :inline="true"
                                :modelValue="dateFilter.filterDate"
                                @update:modelValue='handleDateUpdate'
                                :enableTimePicker="false"
                                :range="true"
                                :format="'dd MMM yyyy'" 
                                ref="dateRangeFilter"
                                selectText="Apply"
                                hideOffsetDates="true"
                                weekStart="0"
                            >
                                <template #calendar-header="{ index }">
                                    <div class="text-xs font-normal text-grey-900 text-center mt-1">
                                        {{ calenderHead[index] }}
                                    </div>
                                </template>
                            </Datepicker>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
        <Container class="!my-12 flex-1 relative">
            <div class="grid gap-8 grid-cols-12">
                <!-- <div class="top-0 col-span-12 md:col-span-12 rounded-lg font-bold bg-red-300 w-full p-4 z-1000">Webcoda Admin Alert: This page doesn't represent any real data and is only placeholder, please check back later.</div> -->
                <div class="col-span-12 lg:col-span-8 !p-0">
                    <div class="border border-grey-200 rounded-lg p-6 hover:shadow-lg duration-300">
                        <p class="text-sm text-black font-bold mb-4">Clients</p>
                        <div class="row" v-if="loading && happyClientsRatio && moderateClientsRatio && sadClientsRatio">
                            <div class="md:col-6 xl:col-3 mb-4 xl:mb-0">
                                <div class="bg-green-200/25 border-green-200 border rounded-md p-3 text-lg font-medium flex items-center justify-center">
                                    <router-link class="flex items-center justify-center" :to="{ name: 'Admin - Beta clients', params: { happinessLevel: 'happy-group'} }">
                                        <IconHappinessHappy class="h-10 w-10 mr-3" />
                                        {{ happyClientsRatio }}%
                                    </router-link>
                                </div>
                            </div>
                            <div class="md:col-6 xl:col-3 mb-4 xl:mb-0">
                                <div class="bg-orange-100/25 border-orange-100 border rounded-md p-3 text-lg font-medium flex items-center justify-center">
                                    <router-link class="flex items-center justify-center" :to="{ name: 'Admin - Beta clients', params: { happinessLevel: 'moderate'} }">
                                        <IconHappinessModerate class="h-10 w-10 mr-3" />
                                        {{ moderateClientsRatio }}%
                                    </router-link>
                                </div>
                            </div>
                            <div class="md:col-6 xl:col-3 mb-4 md:mb-0">
                                <div class="bg-red-100/25 border-red-100 border rounded-md p-3 text-lg font-medium flex items-center justify-center">
                                    <router-link class="flex items-center justify-center" :to="{ name: 'Admin - Beta clients', params: { happinessLevel: 'sad-group'} }">
                                        <IconHappinessSad class="h-10 w-10 mr-3" />
                                        {{ sadClientsRatio }}%
                                    </router-link>
                                </div>
                            </div>
                            <div class="md:col-6 xl:col-3 mb-4 md:mb-0">
                                <div v-if="allClientsTotalAlerts" class="bg-grey-100/25 border-grey-100 border rounded-md p-3 text-lg font-medium flex items-center justify-center">
                                    <IconAlert class="h-10 w-10 mr-3 fill-[#FEEBCC]" />
                                    {{allClientsTotalAlerts}}
                                </div>
                                <Skeletor v-else height="100%" />
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div
                                v-for="i in 4"
                                :key="i"
                                :class="i <= 2 ? 'md:col-6 xl:col-3 mb-4 xl:mb-0' : 'md:col-6 xl:col-3 mb-4 md:mb-0'"
                            >
                                <Skeletor
                                    height="66"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-4 !p-0">
                    <div class="border border-grey-200 rounded-lg p-6 h-full flex items-center justify-between hover:shadow-lg duration-300">
                        <div>
                            <p v-if="!isTeamLoading" class="text-2xl font-medium">{{ billableTotal }}hrs</p>
                            <Skeletor v-else height="28" width="128" class="mb-1" />
                            <p class="text-xs font-bold">Billable hours logged</p>
                            <p class="text-xs">
                                <span v-if="dateFilter.filterActiveType === 'select'">{{dateFilter.selectedValue > 0 ? `Last ${parseInt(dateFilter.selectedValue) + 1} days`: ''}}</span>
                                <span v-if="dateFilter.filterActiveType === 'dateRange'">{{`${convertDateFormat(dateFilter.filterDate[0])} - ${convertDateFormat(dateFilter.filterDate[1])}`}}</span>
                            </p>
                        </div>
                        <div v-if="!isTeamLoading" class="h-16">
                            <circle-progress
                                size="64"
                                :percent="billablePercent"
                                empty-color="#F0F0F0"
                                fill-color="#38A800"
                                border-width="6"
                                border-bg-width="6"
                                linecap="square"
                                show-percent="true"
                            />
                        </div>
                        <Skeletor v-else height="64" width="64" />
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6">
                    <div class="border border-grey-200 rounded-lg h-full">
                        <div class="flex justify-between items-center w-full py-4 px-6">
                            <p class="text-sm font-bold">Clients needing attention</p>
                            <router-link class="py-1.5 px-4 border text-grey-600 bg-grey-50 hover:bg-grey-100 disabled:border-grey-50 disabled:bg-grey-50 disabled:text-grey-200 border-grey-200 rounded text-sm" :to="{name: 'Admin - Beta clients'}">View clients</router-link>
                        </div>
                        <div class="bg-grey-50 px-6 py-2 flex items-center justify-between border-y border-grey-200">
                            <p class="text-sm font-bold">Client</p>
                            <p class="text-sm font-bold flex space-x-2 items-center"><IconAlertSm class="h-4 w-4 mr-3 fill-[#FEEBCC]" /> Alerts</p>
                        </div>
                        <div class="px-6 pt-6 pb-4">
                            <template v-if="highlightedAttentionClients && highlightedAttentionClients.length > 0">
                                <router-link
                                    v-for="(client, clientIndex) in highlightedAttentionClients.slice(0,5)"
                                    :key="clientIndex"
                                    :to="{name: 'Admin - Beta clients', query: { viewing: client.clientId || null } }"
                                    class="flex justify-between items-center py-3 border-b border-grey-200 group min-h-[52px]"
                                >
                                    <div class="space-x-4 flex items-center overflow-hidden">
                                        <div class="shrink-0">
                                            <HappinessRenderer :value="client?.happiness" class="shrink-0" />
                                        </div>
                                        <p class="text-xs font-medium truncate group-hover:underline">{{client?.name}}</p>
                                    </div>
                                    <p class="text-xs font-medium text-warning w-16 text-center">{{ client?.alerts || "" }}</p>
                                </router-link>
                            </template>
                            <div v-else-if="highlightedAttentionClientsLoading">
                                <Skeletor v-for="i in 5" height="44" class="my-2" :key="i" />
                            </div>
                            <div v-else>
                                <div class="py-16 flex flex-col text-center justify-center items-center">
                                    <GreyHappy class="w-[72px] h-[72px]" />
                                    <p class="mt-6 black text-sm">
                                        Nothing to see here - good job! <br />
                                        All of your clients are well taken care of.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex pb-6 px-6 justify-center">
                            <router-link :to='{ name: "Admin - Clients needing attention" }' v-if="highlightedAttentionClients.length > 5" class="text-sm rounded border-2 border-solid border-gray-900 flex items-center py-2 px-5 mr-2">View all</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6">
                    <div class="border border-grey-200 rounded-lg h-full">
                        <div class="flex justify-between items-center w-full py-4 px-6">
                            <p class="text-sm font-bold">Retainers to watch</p>
                            <router-link class="py-1.5 px-4 border text-grey-600 bg-grey-50 hover:bg-grey-100 disabled:border-grey-50 disabled:bg-grey-50 disabled:text-grey-200 border-grey-200 rounded text-sm" :to="{name: 'Retainers Overview', params: {date: yearMonthFormat(new Date())}}">View retainers</router-link>
                        </div>
                        <div class="bg-grey-50 px-6 py-2 flex items-center justify-between border-y border-grey-200">
                            <p class="text-sm font-bold">Retainer</p>
                            <p class="text-sm font-bold flex space-x-2 items-center">Remaining</p>
                        </div>
                        <div class="px-6 pt-6 pb-4">
                            <template v-if="retainersToWatch && retainersToWatch.length > 0">
                                <router-link
                                    v-for="(client, clientIndex) in retainersToWatch.slice(0,5)"
                                    :key="clientIndex"
                                    :to="{name: 'Retainers Overview', params: { date: 'latest' }, query: { search: client?.projectName?.trim() || '' } }"
                                    class="flex justify-between items-center py-3 border-b border-grey-200 group min-h-[52px]"
                                >
                                    <div class="space-x-4 flex items-center overflow-hidden">
                                        <p class="text-xs font-medium truncate group-hover:underline">{{client?.projectName?.trim()}}</p>
                                    </div>
                                    <p class="text-xs font-medium w-16 text-center"
                                        :class="client?.remaining && client?.remaining.includes('-') && 'text-warning'"
                                        >{{ client?.remaining || "" }}</p>
                                </router-link>
                            </template>
                            <div v-else-if="retainersToWatchLoading">
                                <Skeletor v-for="i in 5" height="44" class="my-2" :key="i" />
                            </div>
                            <div v-else>
                                <div class="py-16 flex flex-col text-center justify-center items-center">
                                    <GreyHappy class="w-[72px] h-[72px]" />
                                    <p class="mt-6 black text-sm">
                                        Nothing to see here - good job! <br />
                                        All of your clients are well taken care of.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex pb-6 px-6 justify-center" v-if="retainersToWatch.length > 5">
                            <router-link :to='{ name: "Admin - Retainers to watch" }' class="text-sm rounded border-2 border-solid border-gray-900 flex items-center py-2 px-5 mr-2">View all</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-12">
                    <div class="border border-grey-200 rounded-lg h-full">
                        <div class="flex justify-between border-b border-grey-200 items-center w-full py-4 px-6">
                            <p class="text-sm font-bold">Team top performers</p>
                            <div class="flex items-center">
                                <p class="text-xs mr-3">
                                    <span v-if="dateFilter.filterActiveType === 'select'">{{dateFilter.selectedValue > 0 ? `Last ${parseInt(dateFilter.selectedValue) + 1} days`: ''}}</span>
                                    <span v-if="dateFilter.filterActiveType === 'dateRange'">{{`${convertDateFormat(dateFilter.filterDate[0])} - ${convertDateFormat(dateFilter.filterDate[1])}`}}</span>
                                </p>
                                <router-link class="py-1.5 px-4 border text-grey-600 bg-grey-50 hover:bg-grey-100 disabled:border-grey-50 disabled:bg-grey-50 disabled:text-grey-200 border-grey-200 rounded text-sm" :to="{name: 'Beta Team Overview'}">View team</router-link>
                            </div>
                        </div>
                        <div class="p-6">
                            <Loading :isLoading="isTeamLoading" :loadText="'Loading...'">
                                <div class="block md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    <UserCard
                                        class=""
                                        v-for="(user, index) in topThreeUsers"
                                        :userData="user"
                                        :key="index"
                                        :position="`${index + 1}`"
                                    />
                                </div>
                            </Loading>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    </div>
    <router-view></router-view>
</template>

<script>

    export default {
        data() {
            return {
                billableTarget: 450,
                loading: false,
                isTeamLoading: true,
                retainersToWatchLoading: true,
                highlightedAttentionClientsLoading: true,
                retainersToWatch: [],
                clientsWithAlerts: [],
                retainerData: [],
                allUserDetails: [],
                dateFilter: {
                    isOpened: false,
                    displayType: 'select',
                    selectedValue: 29,
                    filterDate: [new Date().setDate(new Date().getDate() - 30), new Date()],
                    filterActiveType: 'select'
                },
                appSettings: {
                    teamHoursTarget: 60,
                    userHoursTarget: 30
                }
            }
        },
        async mounted() {
            const auth = getAuth();
            this.retainersToWatchLoading = true;

            if(store.getters.getAppSettings) {
                const appSettings = {...store.getters.getAppSettings};
                this.appSettings.teamHoursTarget = appSettings.teamHoursTarget;
                this.appSettings.userHoursTarget = appSettings.userHoursTarget;
                this.billableTarget = parseFloat(this.appSettings.teamHoursTarget) * this.daysExcludingWeekends;
            }
            this.getStats();
            document.addEventListener('click', event => {
                if(event.target.closest('.js-dropdown-calendar') === null) {
                    this.dateFilter.isOpened = false;
                }
            });

            this.retainersToWatch = await RetainersNeedAttention(auth.currentUser.accessToken)
            this.retainersToWatchLoading = false;

            this.loadData()
        },
        computed: {
            billableTotal() {
                const totalHours = this.allUserDetails.reduce((total, user) => total + user.hours.billable, 0).toFixed(2)

                return totalHours
            },
            billablePercent() {
                const dailyTarget = 6
                const totalPercent = this.billableTotal / (this.allUserDetails.length * (6 * this.daysExcludingWeekends)) * 100

                return totalPercent
            },
            highlightedAttentionClients() {
                const clientsNeedAttention = this.clientsWithAlerts.filter(client => {
                    switch (client.happiness) {
                        case "v-sad":
                        case "sad":
                        case "moderate":
                            return true;
                        default:
                            return false;
                    }
                })

                clientsNeedAttention.sort(function(a, b) {
                    return (b.alerts - a.alerts) + (0);
                });

                return clientsNeedAttention
            },
            allClientsTotalAlerts() {
                return this.clientsWithAlerts.reduce((count, client) => count + client.alerts, 0)
            },
            happyClientsRatio() {
                const numberHappyClients = this.retainerData && this.retainerData.reduce((count, client) => client.happiness == "happy" || client.happiness == "v-happy" ? count + 1 : count, 0)
                return ((numberHappyClients / this.retainerData.length) * 100).toFixed(2) || null
            },
            moderateClientsRatio() {
                const numberHappyClients = this.retainerData && this.retainerData.reduce((count, client) => client.happiness == "moderate" ? count + 1 : count, 0)
                return ((numberHappyClients / this.retainerData.length) * 100).toFixed(2) || null
            },
            sadClientsRatio() {
                const numberHappyClients = this.retainerData && this.retainerData.reduce((count, client) => client.happiness == "sad" || client.happiness == "v-sad" ? count + 1 : count, 0)
                return ((numberHappyClients / this.retainerData.length) * 100).toFixed(2) || null
            },
            calenderHead() {
                return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            },
            topThreeUsers() {
                const { allUserDetails } = this;
                if(allUserDetails.length > 3) {
                    return allUserDetails.slice(0, 3);
                }
                return allUserDetails
            },
            totalTeamStats() {

                const { allUserDetails, billableTarget } = this;
                const arr = JSON.parse(JSON.stringify(allUserDetails));
                const flatArray = arr.map(user => {
                    user.hours.taskList.forEach(task => {
                        !task.retainer ? task.retainer = `[No Client Assigned] - ${task.name}` : ''
                    });
                    return [...user.hours.taskList]
                });

                let uniqueTaskArray = flatArray.flat().reduce((a, b) => {
                    let retainer = b.retainer;
                    let found = a.find(item => {
                        return item.retainer == retainer
                    });
                    found ? found.time += b.time : a.push(b)
                    return a;
                }, []);

                return {
                    billable: parseFloat(allUserDetails.reduce((a, b) => a + b.hours.billable, 0).toPrecision(2)),
                    nonBillable: parseFloat(allUserDetails.reduce((a, b) => a + b.hours.nonBillable, 0).toPrecision(2)),
                    billableTarget: billableTarget,
                    taskList: uniqueTaskArray
                }
            },
            daysExcludingWeekends() {
                const { filterDate } = this.dateFilter;
                const fromDate = new Date(filterDate[0]);
                const toDate = new Date(filterDate[1]);
                let count = 0;
                let curDate = new Date(fromDate.getTime());
                while (curDate <= toDate) {
                    const dayOfWeek = curDate.getDay();
                    if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
                    curDate.setDate(curDate.getDate() + 1);
                }
                return count;
            }
        },
        methods: {
            async loadData() {
                const db = getDatabase();
                this.highlightedAttentionClientsLoading = true

                const retainersRef = ref(db, `clients`)

                onValue(retainersRef, async (snapshot) => {

                    const data = snapshot.val();

                    const clients = Object.entries(data).map(entry => {return {...entry[1], clientId: entry[0]}})

                    this.retainerData = clients;

                    this.loading = true

                    const formattedClients = await Promise.all(clients.map(async (item) => {
                        if (!item.retainers) return {...item, alerts: await AlertTotal(item)}

                        const retainersArray = Object.keys(item.retainers).reduce((acc, key) => {
                            if (item.retainers[key]) {
                                const retainer = this.retainersToWatch.find(retainerWatch => retainerWatch.projectID == key);
                                if (retainer) {
                                    acc.push({...retainer});
                                }
                            }
                            return acc;
                        }, []);

                        return {...item, retainers: retainersArray, alerts: await AlertTotal({...item, retainers: retainersArray})}
                    }));

                    this.clientsWithAlerts = formattedClients.filter(c => !c.archived);
                    this.highlightedAttentionClientsLoading = false
                });
            },
            convertDateFormat(date) {
                var options = { day: 'numeric', month: 'short', year: 'numeric' };
                var date = new Date(date);
                return date.toLocaleString('en-GB', options);
            },
            convertToHours(seconds) {
                if(!seconds) {
                    return 0
                }
                var timeInt = parseInt(seconds)
                var hours = (timeInt / 3600)
                return Math.round((hours + Number.EPSILON) * 100) / 100;
            },
            handleDateUpdate(e) {
                this.dateFilter.filterDate = e;
                this.dateFilter.selectedValue= 0,
                this.allUserDetails = [];
                this.dateFilter.filterActiveType = 'dateRange';
                this.getStats();
                this.dateFilter.isOpened = false;
            },
            handleSelectDateFilter(e) {
                const dateValue = e.target.value;
                const newDate = [new Date().setDate(new Date().getDate() - dateValue), new Date()];
                this.dateFilter.selectedValue = dateValue;
                this.dateFilter.filterDate = newDate;
                this.allUserDetails = [];
                this.dateFilter.filterActiveType = 'select';
                this.getStats();
                this.dateFilter.isOpened = false;
            },
            getStats() {
                this.isTeamLoading = true;
                const auth = getAuth();
                const once = auth.onAuthStateChanged(currentUser => {
                    if(currentUser) {

                        const currentToken = auth.currentUser.accessToken;
                        const { filterDate } = this.dateFilter;

                        var from = new Date(filterDate[0]);
                        var to = new Date(filterDate[1]);

                        axios.get(`${this.$root.apiBaseUrl}/team/stats/${from.toISOString().substring(0,10)}/${to.toISOString().substring(0,10)}`, {
                            headers: {
                                'authorization': currentToken,
                                'timezone': this.$root.apiTimezone
                            }
                        }).then(res => {

                            this.billableTarget = parseFloat(this.appSettings.teamHoursTarget) * this.daysExcludingWeekends;

                            this.allUserDetails = res.data.map(x => {
                                return {
                                    name: x.name,
                                    email: x.email,
                                    role: "",
                                    profileImageUrl: x.profileImage ?? "https://via.placeholder.com/100x100",
                                    hours: {
                                        billable: this.convertToHours(x.billable),
                                        billableTarget: parseFloat(this.appSettings.userHoursTarget) * this.daysExcludingWeekends, //TODO:?
                                        nonBillable: this.convertToHours(x.nonbillab),
                                        taskList: x.taskList.map(t => {
                                            return {
                                                name: t.name,
                                                time: this.convertToHours(t.time),
                                                billable: t.billable,
                                                retainer: t.client
                                            };
                                        })
                                    },
                                }
                            });

                            this.isTeamLoading = false;

                        }).catch(err => {
                            console.log(err)
                            this.isTeamLoading = false;
                        });

                    }
                });
                once();
            }
        }
    }
</script>

<style lang="scss">
    .c-datepicker--dropdown {
        border: 0;
        font-family: Montserrat,Arial,"Helvetica Neue",Helvetica,sans-serif;
        &:focus {
            border: 0;
        }
        .dp__calendar_item {
            font-family: Montserrat,Arial,"Helvetica Neue",Helvetica,sans-serif;
            font-size: theme('fontSize.xs');
        }
        .dp__action.dp__select {
            border: 2px solid #010101;
            border-radius: 6px;
            font-size: theme('fontSize.sm');
            display: inline-block;
            padding: 6px 16px;
            color: #010101;
            font-weight: 500;
        }
        .dp__calendar_header_item {
            font-family: Montserrat,Arial,"Helvetica Neue",Helvetica,sans-serif;
        }
        .dp__calendar_header_separator {
            display: none;
        }
        .dp__range_end, .dp__range_start {
            background: theme('colors.grey-900');
            font-weight: bold;
        }
        .dp__range_between {
            font-weight: 500;
            background: theme('colors.grey-50');
        }
        .dp__inner_nav {
            .dp__icon {
                color: theme('colors.grey-900')
            }
        }
    }
    .current-counter {
        &::after {
            content: "%";
        }
    }
</style>

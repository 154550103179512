<script setup>

    import Container from '../containers/Container.vue';
    import Header from '../objects/Header.vue';
    import store from "../../store";
    import Loader from "../objects/Loader.vue";
    import initCurrentUserSession from '../../helpers/InitCurrentUserSession';

    import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, signOut } from "firebase/auth";
	import { getDatabase, ref, child, update, get } from "firebase/database";

    import Close from '~icons/my-icons/cross';
    import CircleTick from '~icons/my-icons/circle-tick';
    import BgImg from '../../assets/images/login-bg.png';

    import LogAuditLog from "@/helpers/logAuditLog";
</script>

<template>
    <!-- <Header :hideNavItems="true" /> -->
    <div class="pt-20 pb-20 flex-1 flex min-h-screen items-center relative bg-auto bg-no-repeat bg-center bg-purple-900" :style="{ backgroundImage: `url('${BgImg}')`}">
        <div v-if="notifications?.message.length > 0" class="absolute top-12 left-1/2 -translate-x-1/2 max-w-[480px] w-full mx-auto bg-orange-100 rounded-lg px-4 py-4 mb-8 flex items-center">
            <div class="self-start flex-shrink-0 mt-1 text-white mr-3 rounded-full bg-orange-400 w-7 h-7 inline-flex items-center justify-center text-sm">
                !
            </div>
            <div class="pl-3 text-xs flex-1"><p>{{notifications.message}}</p></div>
            <button class="self-start ml-6 mr-0 font-medium text-[9px] text-orange-400" @click="notifications.message = ''"><Close/></button>
        </div>
        <Container class="w-full">
            <h1 class="text-4xl font-bold mb-6 text-center text-white">
                Log in to the Webcoda Portal
            </h1>
            <div class="grid sm:grid-cols-2 border border-bd rounded-lg border-solid bg-white u-content-tight">
                <form class="px-12 py-12 sm:py-0 sm:my-12 border-b sm:border-b-0 sm:border-r border-bd border-solid" ref="loginform">
                    <div class="mb-6 relative">      
                        <label>
                            <span class="sr-only">Email address</span>
                            <input 
                                required 
                                @input="handleLoginEmail" 
                                :value="loginEmail" id="email" type="email" 
                                class="block rounded border border-solid border-bd w-full h-12 px-4 focus:outline-0 text-grey-500 placeholder-grey-500" placeholder="Email" /> 
                        </label>                  
                    </div>
                    <div class="mb-6 relative">
                        <label>
                            <span class="sr-only">Password</span>
                            <input 
                                required 
                                @input="handleLoginPassword" 
                                :value="loginPassword" id="password" type="password" 
                                class="block rounded border border-solid border-bd w-full h-12 px-4 focus:outline-0 text-grey-500 placeholder-grey-500" placeholder="Password" /> 
                        </label>  
                    </div>
                    <button class="py-3 px-10 bg-orange-500 rounded-lg text-white w-full" type="submit" @click="submitLogin" :disabled="isLoading">
                        <span class="flex justify-center items-center" v-if="isLoading">
                            <span class="mr-2">Loading...</span>
                            <Loader />
                        </span>
                        <span v-else>
                            Login
                        </span>
                    </button>
                    <div class="mt-4 text-red-500 text-sm" v-if="formError.length > 0">
                        {{formError}}
                    </div>
                    <div class="mt-8 text-center">
                        <router-link to="/forgot-password" class="text-sm text-blue-500 no-underline hover:underline focus:underline">
                            Forgot password?
                        </router-link>
                    </div>
                </form>
                <div class="px-12 my-12 flex items-center">
                    <ul>
                        <li class="inline-flex items-center mb-4 last:mb-0">
                            <CircleTick class="text-orange-500 mr-3 w-6 text-[20px] flex-shrink-0" />
                            Easily manage your retainer
                        </li>
                        <li class="inline-flex items-center mb-4 last:mb-0">
                            <CircleTick class="text-orange-500 mr-3 w-6 text-[20px] flex-shrink-0" />
                            Review time log reports
                        </li>
                        <li class="inline-flex items-center mb-4 last:mb-0">
                            <CircleTick class="text-orange-500 mr-3 w-6 text-[20px] flex-shrink-0" />
                            Add contacts from your organisation
                        </li>
                        <li class="inline-flex items-center mb-4 last:mb-0">
                            <CircleTick class="text-orange-500 mr-3 w-6 text-[20px] flex-shrink-0" />
                            Review your support plan &amp; other services
                        </li>
                        <li class="inline-flex items-center mb-4 last:mb-0">
                            <CircleTick class="text-orange-500 mr-3 w-6 text-[20px] flex-shrink-0" />
                            Rate our performance
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mt-8 text-center text-white text-sm">
                <p>Version: {{appVersion}}</p>
            </div>
        </Container>
    </div>
</template>

<script>

    import { initializeApp } from "firebase/app";    
    import { version } from "../../../package.json"
    
    export default {
        data() {
            return {
                loginEmail: "",
                loginPassword: "",
                formError: "",
                isLoading: false,
                appVersion: version,
                notifications: {
                    type: this.notificationType ? this.notificationType : '',
                    message: this.notification ? this.notification : ''
                }
            }
        },
        props: ['notificationType', 'notification'],
        mounted() {
            localStorage.removeItem('webcodaRetainerPortalExpTime');
            const auth = getAuth();
			const checkAuth = auth.onAuthStateChanged(user => {
                if (user?.reloadUserInfo) {
                    const userClaims = JSON.parse(user.reloadUserInfo.customAttributes)
                    const clientId = userClaims.client || ''

                    const dbRef = ref(getDatabase());

                    get(child(dbRef, `/clients/${clientId}`)).then((snapshot) => {
                        if (snapshot.exists()) {
                            const data = snapshot.val();
                            const firstRetainer = data?.retainers ? Object.keys(data?.retainers)[0] : 0

                            if (user && firstRetainer) {
                                this.$router.push({name: 'Beta Dashboard Home', params: {clientId, retainerId: firstRetainer}})
                            } else if (userClaims.permission == 'admin') {
                                this.$router.push({name: 'Beta Admin Dashboard'})
                            } else {
                                console.log("No data available");
                            }
                        } else {
                            if (userClaims.permission == 'admin') {
                                this.$router.push({name: 'Beta Admin Dashboard'})
                            } else {
                                console.log("No data available");
                            }
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            });
            checkAuth();
        },
        methods: {
            submitLogin(e) {
                e.preventDefault();
                const form = this.$refs.loginform;
                this.formError = '';
                if(form.reportValidity()) {
                    this.isLoading = true;
                    const auth = getAuth();

                    setPersistence(auth, browserLocalPersistence).then(() => {
                        
                        signInWithEmailAndPassword(auth, this.loginEmail, this.loginPassword).then(res => {
                            const user = res.user;
                            const userClaims = JSON.parse(user.reloadUserInfo.customAttributes)

                            if(!res.user.emailVerified) {
                                this.$router.push({ name: 'UserSetup', query: { mode: "setupWithPassword"}})
                                return;
                            }

                            const db = getDatabase();
                            const updates = {};

                            updates[`/users/${user.uid}/lastLoginTime`] = user.metadata.lastSignInTime;
                            update(ref(db), updates).then(res => {});
                            const url = new URL(window.location.href);

                            initCurrentUserSession(this).then(res => {
                                store.commit('updateActiveRetainerState', null);
                                store.commit('updateViewAsClient', null);
                                store.commit('updateAllProjects', []);
                                let updateClient = {};
                                const userRetainers = res.retainer;

                                console.log("res")
                                console.log(res)

                                const clientId = res.client || ""

                                const dbRef = ref(db);

                                get(child(dbRef, `/clients/${clientId}`)).then((snapshot) => {
                                    if (snapshot.exists()) {
                                        const data = snapshot.val();
                                        const firstRetainer = data?.retainers ? Object.keys(data?.retainers)[0] : 0

                                        LogAuditLog({
                                            type: "User",
                                            object: "Login",
                                            action: "run",
                                            data: {
                                                id: user.uid,
                                                clientId: userClaims?.client || null,
                                                retainerId: firstRetainer || null
                                            }
                                        })

                                        const routerObj = {
                                            query: {},
                                            params: {clientId, retainerId: firstRetainer || 0}
                                        };

                                        if(url.searchParams.get("redirect") && url.searchParams.get("redirect").length > 0) {
                                            routerObj.name = url.searchParams.get("redirect");
                                        } else {
                                            routerObj.name = res.permission == 'admin' ? 'Beta Admin Dashboard' : 'Beta Dashboard Home'
                                        }

                                        url.searchParams.get("date") ? routerObj.query.date = url.searchParams.get("date") : '';

                                        if(userRetainers) {
                                            const promises = userRetainers.map(item => new Promise(async resolve => {
                                                updateClient[`/retainerUsers/${item}/${user.uid}/lastLoginTime`] = user.metadata.lastSignInTime ? user.metadata.lastSignInTime : new Date();
                                                updateClient[`/retainerUsers/${item}/${user.uid}/isVerified`] = true;
                                                update(ref(db), updateClient).then(() => {
                                                    resolve(`Deleted user in retainer ${item}`);
                                                });
                                            }));

                                            Promise.all(promises).then(() => {
                                                this.$router.push(routerObj);
                                            });
                                        } else {
                                            this.$router.push(routerObj);
                                        }
                                    } else {
                                        if (res.permission == 'admin') {
                                            this.$router.push({name: 'Beta Admin Dashboard'})
                                        } else {
                                            console.log("No data available");
                                        }
                                    }
                                }).catch((error) => {
                                    console.error(error);
                                });
                            });
                            
                        }).catch(error => {
                            this.isLoading = false;
                            switch(error.code) {
                                case 'auth/user-not-found':
                                    this.formError = 'The user email you have entered cannot be found.';
                                    break;
                                case 'auth/invalid-email':
                                    this.formError = 'You have entered an invalid email.';
                                    break;
                                case 'auth/wrong-password':
                                    this.formError = 'You have entered an incorrect password.';
                                    break;
                                case 'auth/too-many-requests':
                                    this.notifications.message = error.message;
                                    break;
                                default: 
                            }
                        });

                    }).catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                    });

                } 
            },
            handleLoginEmail(e) {
                this.loginEmail = e.target.value;
            },
            handleLoginPassword(e) {
                this.loginPassword = e.target.value;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .u-content-tight {
        max-width: 900px;
        margin: 0 auto;
    }
</style>

<script setup>

    import axios from "axios";
    import Multiselect from '@vueform/multiselect';
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import IconTick from '~icons/my-icons/tick';
    import IconChevronDown from '~icons/my-icons/chevron-down';
    import CrossIcon from '~icons/my-icons/cross';
    import Search from '~icons/my-icons/search';

    import { getDatabase, get, child, ref, onValue, update } from "firebase/database";
    import store from "../store";
    
    import UserAvatar from '@/components/objects/UserAvatar.vue';
	import UserDropdown from '@/components/objects/UserDropdown.vue'
	import UserSelectDropdown from '@/components/objects/UserSelectDropdown.vue'

    import { SearchIcon } from "@heroicons/vue/outline";
</script>

<template>
    <div class="mb-3" :class="[inputType == 'userSelect' ? '!items-start' : '', inputType !== 'textarea' && inputType !== 'url' && !type ? 'items-top' : '', type === 'column' ? 'flex-col' : 'last:mb-0', fullWidth ? 'block' : 'flex']">
        <label 
            v-html="inputLabel" 
            class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3"
            :for="inputName" 
            :class="[inputType == 'userSelect' ? 'mt-7' : '', inputType !== 'textarea' && inputType !== 'url' ? '' : 'mt-1', type === 'column' ? 'mb-2' : '', theme == 'dark' ? 'font-bold' : 'text-grey-300', fullWidth ? 'block w-full mb-2' : 'inline-block']"
        >
        </label>
        <span class="flex-1 w-full flex-shrink-0" v-if="inputType === 'textarea' || inputType === 'url'">
            <textarea
                class="flex-1 w-full border border-bd border-solid h-[128px] rounded p-3"
                @input='$emit("update:val", $event.target.value)'
                :value="val"
                :placeholder="placeholder"
                :id="inputName"
            >
            </textarea>
            <span class="text-xs block mt-1 text-grey-300 mb-3" v-if="inputType === 'url'">
                For multiple URLs, please enter each URL separated by a comma in between.
            </span>
        </span>
        <div v-else-if="inputType === 'file'" class="w-full flex-1 flex-shrink-0">
            <div class="mb-4">
                <img :src="imageVal || val" alt="" class="max-w-[200px]" />
            </div>
            <input
                class="border border-bd border-solid h-12 rounded px-4 py-2 w-full"
                type="file"
                :ref="inputName"
                @input='handleUploadImage'
                :id="inputName"
                accept="image/png, image/gif, image/jpeg"
            >
        </div>
        <div v-else-if="inputType === 'date'" class="w-full">
            <Datepicker
                :modelValue="inputDate"
                @update:modelValue='handleDateUpdate'
                :ref="inputName"
                :autoApply="true"
                :required="required"
                :enableTimePicker="false"
                :format="'d MMMM yyyy'"
            />
        </div>
        <div v-else-if="inputType === 'month'" class="w-full">
            <Datepicker
                monthPicker
                :modelValue="inputMonth"
                @update:modelValue='handleMonthUpdate'
                :ref="inputName"
                :autoApply="true"
                :required="required"
                :enableTimePicker="false"
                :format="'MMMM yyyy'"
            />
        </div>
        <div v-else-if="inputType === 'team'" class="w-full">
            <Multiselect
                :modelValue="val"
                :searchable="true"
                :required="required"
                :options="allTeamsList"
                :canClear="false"
                @change='handleSelect'
            >
                <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                        <div class="truncate">
                            <h1 class="inline">{{value.label}}</h1>
                        </div>
                    </div>
                </template>
            </Multiselect>
        </div>
        <div v-else-if="inputType === 'userSelect'" class="w-full bg-gray-50 p-3 rounded">
            <!--<Multiselect
            v-model="val"
            mode="tags"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            :options="clientAdminUsers"
            @tag="handleTag"
            @input="handleSelect"
            >
                <template #singlelabel="{ option }">
                    <div class="multiselect-single-label">
                        <div class="truncate">
                        <h1 class="inline">{{ option.label }}</h1>
                        </div>
                    </div>
                </template>
            </Multiselect>-->
            <UserSelectDropdown
                :filteredAccounts="activeUsers"
                @click="handleClick"
                :showModal="showModal"
                :filterUsers="allUsers"
                @handleRemoveUser="userId => $emit('handleRemoveUser', userId)"
                @handleAddUser="userId => $emit('handleAddUser', userId)"
                :selectedUsers="selectedUsers"
                :isActiveLabel="isActiveLabel"
                v-click-outside-element="close"
            />
            <div
                    v-if="activeUsers"
                    v-for="user in activeUsers"
                    :key="user.uid"
                    class="flex items-center justify-between "
                >
                    <div class="flex items-center mb-4 mt-4 last:mb-0">
                        <div
                            v-if="(!user.profileImage)"
                            class="inline-block w-[48px] h-[48px] rounded-full "
                        >
                            <span class=" bg-black text-white rounded-full bg-center bg-cover inline-flex items-center justify-center w-[48px] h-[48px] text-xs">
                                <UserAvatar :name="`${user.firstName} ${user.lastName} ${user.permission === 'client-admin' ? '(Admin)' : ''}`" text-size="text-sm" />
                            </span>
                        </div>
                        
                        <div v-else class="">
                            <img
                                class="inline-block w-[48px] h-[48px] rounded-full" 
                                :src="user.profileImage ? user.profileImage : ''" 
                                alt=""
                            />
                        </div>
                        <div class="ml-4 text-xs">
                                <p class="text-gray-900 font-normal">{{ user.firstName + ' ' + user.lastName }}</p>
                                <p class="text-gray-400">{{ user.email }}</p>
                        </div>
                    </div>
                    <UserDropdown
                        v-if="user.uid"
                        :type="userSelectType == 'admin' ? 'adminUsers' : 'admin'"
                        value="view"
                        options="includeRemove"
                        :userData="user"
                        :hideDelete="true"
                        @handleCopyEmail="handleCopyEmail(user.email)"
                        @handleDeleteModal="handleDeleteUserModal"
                        @handleRemoveUser="userId => $emit('handleRemoveUser', userId)"
                        :disabled="isLoading"
                        class="mr-3"
                    />
            </div>
            <div v-else>
                <p class="pt-4 text-xs">No contact set...</p>
            </div>
        </div>
        <div v-else-if="inputType === 'clientSelect'" class="w-full bg-gray-50 p-3 rounded">
            <UserSelectDropdown
                :filteredAccounts="filteredClientAccounts"
                @click="handleClick"
                :showModal="showModal"
                :filterUsers="filterUsers"
                :isActiveLabel="isActiveLabel"
                @handleRemoveUser="userId => $emit('handleRemoveUser', userId)"
                @handleAddUser="userId => $emit('handleAddUser', userId)"
                :selectedUsers="selectedUsers"
                v-click-outside-element="close"
            />
            <div
                v-if="filteredClientAccounts.length"
                v-for="user in filteredClientAccounts"
                :key="user.uid"
                class="flex items-center justify-between "
            >
                    <div class="flex items-center mb-4 mt-4 last:mb-0">
                        <div
                            v-if="(!user.profileImage)"
                            class="inline-block w-[48px] h-[48px] rounded-full "
                        >
                            <span class=" bg-black text-white rounded-full bg-center bg-cover inline-flex items-center justify-center w-[48px] h-[48px] text-xs">
                                <UserAvatar :name="`${user.firstName} ${user.lastName} ${user.permission === 'client-admin' ? '(Admin)' : ''}`" text-size="text-sm" />
                            </span>
                        </div>
                        
                        <div v-else class="">
                            <img
                                class="inline-block w-[48px] h-[48px] rounded-full" 
                                :src="user.profileImage ? user.profileImage : ''" 
                                alt=""
                            />
                        </div>
                        <div class="ml-4 text-xs">
                                <p class="text-gray-900 font-normal">{{ user.firstName + ' ' + user.lastName }}</p>
                                <p class="text-gray-400">{{ user.email }}</p>
                        </div>
                    </div>
                    <UserDropdown
                        type="admin"
                        value="view"
                        options="includeRemove"
                        :userData="user"
                        :hideDelete="true"
                        @handleCopyEmail="handleCopyEmail(user.email)"
                        @handleDeleteModal="handleDeleteUserModal"
                        @handleRemoveUser="userId => $emit('handleRemoveUser', userId)"
                        :disabled="isLoading"
                        class="mr-3"
                    />
            </div>
            <div v-else>
                <p class="pt-4 text-xs">No contact set...</p>
            </div>
        </div>
        <div v-else-if="inputType === 'select'" class="w-full relative">
            <select
                @change='$emit("update:val", $event.target.value); $emit("onChange", $event.target.value)'
                class="flex-1 w-full border border-bd border-solid rounded py-3 px-4 pr-10 appearance-none"
                :value="val"
                :required="required"
            :placeholder="placeholder"
                :id="inputName"
            >
                <option value=""></option>
                <option v-for="item in selectList" :key="item.value" :value="item.value">{{ item.label }}</option>
            </select>
            <IconChevronDown class="absolute top-1/2 right-3 -translate-y-1/2 pointer-events-none"/>
        </div>
        <label v-else-if="inputType === 'checkbox'" class="relative inline-flex items-center cursor-pointer">
            <span class="relative flex">
                <input class="peer sr-only" :checked="val" :required="required" type="checkbox" :id="inputName" @input='$emit("update:val", $event.target.checked)' >
                <span class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>
                <span class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <IconTick class="text-white"/>
                </span>
            </span>	
        </label>
        
        <input class="border border-bd border-solid h-12 rounded px-4 flex-shrink-0" :class="[type == 'column' ? 'flex-auto' : 'flex-1', fullWidth && 'flex w-full']"
            v-else-if="inputType === 'number'"
            type="number"
            min="0"
            step="0.01"
            @input='$emit("update:val", $event.target.value)'
            :value="val"
            :required="required"
            :placeholder="placeholder"
            :id="inputName"
        >
        <div v-else-if="inputType === 'money'" class="w-full before:content-['$'] before:absolute before:block before:left-[18px] before:top-1/2 before:-translate-y-1/2 relative">
            <input class="w-full border border-bd border-solid h-12 rounded px-4 flex-shrink-0 pl-8" :class="[type == 'column' ? 'flex-auto' : 'flex-1', fullWidth && 'flex w-full']"
                type="number"
                min="0"
                step="0.01"
                :required="required"
                @input='$emit("update:val", $event.target.value)'
                :value="val"
                :placeholder="placeholder"
                :id="inputName"
            >
        </div>
        <div v-else-if="inputType == 'static'" class="h-8 flex items-center">
            {{ val }}
        </div>
        <div v-else-if="inputType == 'html'" class="min-h-[32px] flex items-center">
            <slot></slot>
        </div>
        <input class="border border-bd border-solid h-12 rounded px-4 flex-shrink-0" :class="[type == 'column' ? 'flex-auto' : 'flex-1', fullWidth && 'flex w-full']"
            v-else-if="inputType === 'email'"
            type="email"
            @input='$emit("update:val", $event.target.value)' 
            :value="val"
            :required="required"
            :placeholder="placeholder"
            :id="inputName"
        >
        <input class="border border-bd border-solid h-12 rounded px-4 flex-shrink-0" :class="[type == 'column' ? 'flex-auto' : 'flex-1', fullWidth && 'flex w-full']"
            v-else
            type="text"
            @input='$emit("update:val", $event.target.value)' 
            :value="val"
            :required="required"
            :placeholder="placeholder"
            :id="inputName"
        >
    </div>
</template>

<script>
    export default {
    props: ["name", "label", "allUsers", 'userSelectType', "filterAdminUsers", "clientUsers", "isActiveLabel", "managedClient", "filteredClientAccounts", "filteredAccounts", "activeUsers", "val", "initValue", "inputType", "type", "theme", "selectList", "fullWidth", "selectedUsers", "clientId", "handleRemoveUser", "handleAddUser", 'filterUsers'],
    data() {
        return {
            query: this.propsQuery ? this.propsQuery : '',
            imageVal: this.val,
            inputDate: this.val ? this.val : "",
            inputMonth: "",
            inputName: this.name,
            inputLabel: this.label ? this.label : "",
            initialValue: this.initValue ? this.initValue : "",
            allTeamsList: [],
            clientAdminUsers: [],
            showModal: false,
        };
    },
    watch: {
        val(newVal, oldVal) {
            if (this.inputType == "date") {
                this.handleDateUpdate(newVal);
            }
        }
    },
    created() {
    },
    computed: {
    },
    methods: {
        close() {
            this.showModal = false
        },
        handleClick() {
            this.showModal = !this.showModal;
        },
        handleRemoveUser(userId) {
            this.$emit('handleRemoveUser', userId);
        },
        handleAddUser(userId) {
            this.$emit('handleAddUser', userId);
        },
        handleDateUpdate(e) {
            this.inputDate = e;
            var options = { day: 'numeric', month: 'long', year: 'numeric' };
            var date = new Date(e);
            this.$emit("update:val", date.toLocaleString('en-GB', options))
        },
        handleMonthUpdate(e) {
            this.inputMonth = e;
            var options = { day: 'numeric', month: 'long', year: 'numeric' };
            var date = new Date( `${e.year}-${e.month+1}-1`);
            this.$emit("update:val", date.toLocaleString('en-GB', options))
        },
        handleUploadImage() {
            const fileRef = this.$refs[this.inputName];
            const file = fileRef.files[0];
            this.$emit('update:val', file);
            // Handle preview
            this.imageVal = URL.createObjectURL(file)
        },
        handleSelect(val) {
            this.$emit('update:val', val);
        },

        handleCopyEmail(email) {
            if (email) {
                navigator.clipboard.writeText(email).then(() => {
                    this.$notify({
                        title: "",
                        text: "Email address successfully copied to your clipboard",
                        duration: 5000,
                    });
                }, function (err) {
                    console.error("Async: Could not copy text: ", err);
                });
            }
        },
        customLabel({ label }) {
            return label;
        },
        getOptionId(option) {
            return option.value;
        },
        getOptionLabel(option) {
            return option.label;
        },
        removeOption(index) {
            this.selectedValues.splice(index, 1);
        },
    }
    }
</script>

<style lang="scss">
    .multiselect-dropdown {
        height: 30rem !important;
    }
</style>
<script setup>
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, ref, onValue, update, remove } from "firebase/database";
import { getApp } from "firebase/app";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

import axios from "axios";
import initAllProjects from '../../helpers/InitAllProjects';
import store from '../../store';

import AuthValidation from '../containers/AuthValidation.vue';
import ConfirmationDialog from "../containers/ConfirmationDialog.vue";
import deleteUser from "../../helpers/DeleteUser";
import Loading from '../containers/Loading.vue';

import allRoles from "../../data/alluserRoles";
import allAccountSettings from "../../data/accountSettingsData";
import userCanLogin from "../../data/userCanLogin";
import updateRetainerUserDb from '../../helpers/UpdateRetainerUserDb';
import ConvertTimeHelper from '../../helpers/ConvertTimeHelper';
import UserAvatar from '../objects/UserAvatar.vue';
import RetainerDropdown from '@/components/objects/RetainerDropdown.vue';

import Tick from '~icons/my-icons/tick';
import Close from '~icons/my-icons/cross';
import IconTick from '~icons/my-icons/tick';
import vSelect from 'vue-select'

import LogAuditLog from "@/helpers/logAuditLog";

</script>

<template>
  <div class="fixed top-0 left-0 w-full min-h-screen h-full overflow-auto bg-grey-900/40 z-[100]">
    <!-- <div class="absolute top-0 left-0 w-full h-full"></div> -->
    <div class="max-w-[800px] w-full bg-grey-50 ml-auto h-screen flex flex-col z-[2] relative">
      <div class="px-6 py-5 border-b border-grey-200 bg-white flex justify-between items-center">
        <h3 class="font-bold text-lg">Contact:
          <span class="font-normal">{{ user.firstName + ' ' + user.lastName }}</span>
        </h3>
        <Close @click="$router.go(-1)" class="cursor-pointer w-4 h-4" />
      </div>
      <div class="w-full flex flex-col overflow-auto">
        <AuthValidation>
          <div class="p-6 flex flex-col gap-4">
            <div v-if="showNotification"
              class="relative max-w-[840px] w-full mx-auto bg-green-200 rounded-lg px-4 py-4 mb-8 flex items-center">
              <div
                class="text-white mr-3 rounded-full bg-green-800 w-7 h-7 inline-flex items-center justify-center text-sm">
                <Tick />
              </div>
              <div class="font-bold text-green-800 mr-3">User updated</div>
              <div class="border-l border-bd border-solid pl-3 text-xs">User details have been updated.</div>
              <button class="ml-auto mr-0 font-medium text-[9px] text-green-800"
                @click="showNotification = !showNotification">
                <Close />
              </button>
            </div>
            <div class="max-w-[840px] w-full mx-auto bg-white rounded-lg border-solid border">
              <div v-if="!isEdit">
                <Loading :isLoading="submitting" :type="'overlay'" :loadText="'Processing...'">
                  <div class="border-b border-bd px-6 py-4">
                    <p class="font-bold text-sm">Details</p>
                  </div>
                  <div class="flex flex-col gap-4 p-6">
                    <div class="flex justify-between">
                      <div class="flex flex-col gap-4">
                        <div class="flex">
                          <div class="text-xs text-grey-900 font-bold w-[150px] pr-2 flex-shrink-0">
                            Last
                            Active</div>
                          <div class="text-xs w-full">{{ user?.lastLoginTime ? user?.lastLoginTime : 'Registration pending' }}</div>
                        </div>
                        <div class="flex" v-if="editUser.userCanLogin">
                          <div class="text-xs text-grey-900 font-bold w-[150px] pr-2 flex-shrink-0">
                            Password
                          </div>
                          <div class="text-xs w-full">
                            <router-link v-if="editUser.userCanLogin"
                              :to="{ name: 'ChangePassword', params: { userid: uid } }"
                              class="inline-block mr-6 text-blue-500 underline font-medium">Change
                              password</router-link>
                          </div>
                        </div>
                        <div class="flex" v-if="isWebcodaAdmin">
                          <div class="text-xs text-grey-900 font-bold w-[150px] pr-2 flex-shrink-0">
                            Account
                          </div>
                          <div class="text-xs w-full">
                            <button v-if="showDeleteButton" class="text-red-500 underline font-medium"
                              @click="handleDeleteUserModal()">Delete account</button>
                          </div>
                        </div>
                      </div>
                      <div class="flex justify-end">
                        <span
                          class="mr-4 rounded-full overflow-hidden flex-shrink-0 min-w-[80px] min-h-[80px] max-w-[80px] max-h-[80px] inline-flex items-center justify-center bg-center bg-cover bg-black"
                          :style="{ backgroundImage: `url(${user.profileImage})` }">
                          <UserAvatar class="text-3xl" v-if="!user.profileImage"
                            :name="`${user?.firstName} ${user?.lastName}`" />
                        </span>
                      </div>
                    </div>
                    <div>
                      <div class="flex py-4 border-t border-grey-100">
                        <div class="text-xs text-grey-900 font-bold w-[150px] pr-2 flex-shrink-0">
                          Email
                        </div>
                        <div class="text-base w-full">
                          {{ user.email }}
                        </div>
                      </div>
                      <div class="flex flex-col  mb-4 last:mb-0">
                        <div class="flex items-center">
                          <label class="min-w-[125px] text-xs text-grey-900 font-bold mr-3" for="firstName">
                            First name
                          </label>
                          <input class="flex-1 border border-bd border-solid h-12 rounded px-4"
                            v-model="editUser.firstName" id="firstName" required>
                        </div>
                          <div v-if="firstNameError" class="flex ml-[125px] pl-4 mt-2 text-error text-sm">{{ firstNameError }}</div>
                      </div>
                      <div class="flex flex-col  mb-4 last:mb-0">
                        <div class="flex items-center">
                          <label class="min-w-[125px] text-xs text-grey-900 font-bold mr-3" for="lastName">
                            Last name
                          </label>
                          <input class="flex-1 border border-bd border-solid h-12 rounded px-4"
                            v-model="editUser.lastName" id="lastName" required>
                        </div>
                          <div v-if="lastNameError" class="flex ml-[125px] pl-4 mt-2 text-error text-sm">{{ lastNameError }}</div>
                      </div>
                      
                    </div>
                    <!--
                                    <div>
                                        <div class="flex items-center mb-3">
                                            <label class="min-w-[125px] text-xs text-grey-300 mr-3" for="email">
                                                Email
                                            </label>
                                            <input class="flex-1 border border-bd border-solid h-12 rounded px-4"
                                                v-model="editUser.email" id="email" required>
                                        </div>
                                    </div>
                                    -->
                    <div>
                      <div class="flex items-center">
                        <label class="min-w-[125px] text-xs text-grey-900 font-bold mr-3" for="roleTitle">
                          Job Title
                        </label>
                        <input class="flex-1 border border-bd border-solid h-12 rounded px-4"
                          v-model="editUser.roleTitle" id="roleTitle">
                      </div>
                    </div>
                    <div>
                      <div class="flex items-center mb-3">
                        <label class="min-w-[125px] text-xs text-grey-900 font-bold mr-3" for="phone">
                          Contact number
                        </label>
                        <input class="flex-1 border border-bd border-solid h-12 rounded px-4" v-model="editUser.phone"
                          id="phone">
                      </div>
                    </div>
                    <hr class="bg-grey-100 h-px w-full mb-3">
                    <div v-if="(isWebcodaAdmin)">
                      <div class="flex items-center mb-3 last:mb-0">
                        <label class="min-w-[125px] text-xs text-grey-900 font-bold mr-3" for="team">
                          Client
                        </label>
                        <div class="flex flex-col w-full">
                          <v-select class="w-full"
                              v-model="editUser.client" 
                              :options="sortedFirebaseRetainers 
                                ? sortedFirebaseRetainers.map(client => client ? {label: client.name, id: client.clientId} : {}) 
                                : []" 
                              :reduce="(option) => option.id"
                              label="label"
                              :clearable="false"
                              required
                              v-if="isWebcodaAdmin">
                            </v-select>
                            <span v-else>
                                {{ (sortedFirebaseRetainers.find(client => client.clientId === editUser.client) || {}).name }}
                            </span>
                            <router-link v-if="(isWebcodaAdmin) && editUser?.client"
                                :to="{ name: 'Admin - Edit client', params: { clientId: editUser?.client } }"
                                class="inline-block text-xs mt-2 px-0 text-blue-500 underline font-medium">
                                View client
                            </router-link>
                        </div>
                      </div>
                      <!--
                                            <div class="flex items-center mb-3 last:mb-0">
                                                <label class="min-w-[125px] text-xs text-grey-900 font-bold mr-3"
                                                    for="retainer">
                                                    Retainer
                                                </label>
                                                <Multiselect class="z-30" v-model="editUser.retainer" mode="multiple"
                                                    :hideSelected="false" :object="true" :options="allRetainers">
                                                    <template v-slot:multiplelabel="{ values }">
                                                        <div class="multiselect-multiple-label">
                                                            <span class="flex-shrink-0" v-for="(val, index) in values"
                                                                :key="val.value">
                                                                {{ val.label }}<span class="inline-block"
                                                                    v-if="values.length !== 1 && index != Object.keys(values).length - 1">,&nbsp;</span>
                                                            </span>
                                                        </div>
                                                    </template>
</Multiselect>
</div>
-->
                    </div>
                    <!-- <div>
                      <div v-if="isWebcodaAdmin" class="flex items-center mb-3 last:mb-0">
                        <label class="min-w-[125px] text-xs text-grey-900 font-bold mr-3" for="permission">
                          Permissions
                        </label>
                        <select class="flex-1 border border-bd border-solid h-12 rounded px-4 appearance-none"
                          v-model="editUser.permission" id="permission" required>
                          <option value="">Please select</option>
                          <option :value="role.value" v-for="role in allRoles" :key="role.value">
                            {{ role.label }}</option>
                        </select>
                      </div>
                    </div> -->
                    <div>
                      <div class="flex mb-3 last:mb-0">
                        <div class="min-w-[125px] text-xs text-grey-900 font-bold mr-3" for="account-settings">
                          Contact type
                        </div>
                        <div class="flex flex-col">
                          <label v-for="setting in allAccountSettings" :key="setting.value"
                            class="relative inline-flex items-center  mb-2 last:mb-0"
                            :class="[!isBothAdmin ? 'cursor-not-allowed' : 'cursor-pointer']">
                            <span class="relative flex">
                              <input class="peer sr-only" :disabled="!isBothAdmin"
                                :value="setting.value" type="checkbox" @change="handleCheckboxUpdate(setting.value)"
                                :checked="editUser.accountSettings.filter(a => a === setting.value).length > 0">
                              <span
                                :class="[!isBothAdmin ? 'peer-checked:bg-grey-300' : 'peer-checked:bg-grey-800']"
                                class="inline-block w-5 h-5 border border-grey-300 rounded p-1"></span>
                              <span
                                class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                <IconTick class="text-white" />
                              </span>
                            </span>
                            <span class="ml-3" :class="[!isBothAdmin ? 'text-grey-300 pointer-events-none' : '']">
                              {{  setting.label }}
                              
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- <DeleteUserConfirmation 
                        :isActive="deleteModalActive" 
                        :selectedUser="selectedUser" 
                        @handleModalClose="handleDeleteModalClose" 
                    /> -->
                    <ConfirmationDialog :isActive="deleteModalActive"
                      headerText="Are you sure you want to delete this account?"
                      :descriptionText="deleteUserModalDescription" confirmButtonText="Delete account"
                      @handleModalClose="handleDeleteModalClose" @handleModalConfirm="handleDeleteUserModalConfirm" />
        <!-- <hr class="bg-grey-100 h-px w-full"> -->
        <!-- <div>
          <div class="flex mb-3 last:mb-0">
            <div class="min-w-[125px] text-xs text-grey-900 font-bold mr-3" for="account-settings">
              Account settings
            </div>
            <div class="flex flex-col">
              <label v-for="setting in userCanLogin" :key="setting.value"
                class="relative inline-flex items-center  mb-2 last:mb-0"
                :class="[!isBothAdmin ? 'cursor-not-allowed' : 'cursor-pointer']">
                <span class="relative flex">
                  <input class="peer sr-only" :disabled="!isBothAdmin" :value="setting.value"
                    type="checkbox" @change="handleCanLoginUpdate" :checked="editUser.userCanLogin">
                  <span
                    :class="[!isBothAdmin ? 'peer-checked:bg-grey-300' : 'peer-checked:bg-grey-800']"
                    class="inline-block w-5 h-5 border border-grey-300 rounded p-1"></span>
                  <span
                    class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <IconTick class="text-white" />
                  </span>
                </span>
                <span class="ml-3" :class="[!isBothAdmin ? 'text-grey-300' : '']">{{
                setting.label }}</span>
              </label>
            </div>
          </div>
        </div> -->
      </div>
      </Loading>
    </div>
  </div>
  </div>
  </AuthValidation>
  </div>
  <div class="text-right py-3 px-6 flex items-center justify-end mt-auto bg-white border-t border-grey-200 w-full">

    <div class="text-right flex justify-end items-center">
      <v-btn @click="$router.go(-1)" class="text-blue-500 mr-6 cursor-pointer">
        Cancel
      </v-btn>
      <button type="button"
        class="transition-all py-2.5 px-5 bg-orange-500 rounded text-white disabled:text-grey-300 disabled:bg-grey-100"
        @click="handleUserUpdate">
        Save
      </button>
    </div>
  </div>
  </div>
  </div>
</template>

<script>

export default {
  props: ['userid'],
  data() {
    return {
      authUserUID: '',
      user: {
        retainer: []
      },
      isEdit: false,
      editField: "",
      showNotification: false,
      uid: this.userid,
      allRetainers: [],
      allTeams: [],
      editUser: {
        roleTitle: '',
        phone: '',
        accountSettings: [],
        userCanLogin: false,
      },
      deleteModalActive: false,
      selectedUser: {},
      deleteModalActive: false,
      selectedUser: {},
      submitting: false,
      firebaseRetainers: [],
      showModal: false,
      managedClient: [],
      firstNameError: '',
      lastNameError: '',
      // selectedClientId: null,
      // selectedClientName: null,
    }
  },
  watch: {
    "$route.params.userid"(val) {
      if (val) {
        this.uid = val;
        this.getUserData();
      }
    },

  },
  computed: {
    filteredClients() {
      return this?.firebaseRetainers?.filter(user => {
        return this?.managedClient?.includes(user?.clientId);
      }).map(user => {
        return {
          uid: user.clientId,
          clientName: user.name,
        };
      }).sort((a, b) => a.clientName.localeCompare(b.clientName));
    },
    sortedFirebaseRetainers() {
  return this.firebaseRetainers 
    ? this.firebaseRetainers
        .filter(client => client && !client.archived)
        .sort((a, b) => a.clientId - b.clientId)
    : [];
},
    isSelf() {
      return this.authUserUID === this.uid
    },
    isWebcodaAdmin() {
      const currentUser = store.getters.getUserState;
      const viewAsClient = store.getters.getViewAsClient;
      if (viewAsClient) {
        return false
      }
      return currentUser.permission === 'admin'
    },
    isBothAdmin() {
      const currentUser = store.getters.getUserState;
      return currentUser.permission === 'admin' || currentUser.permission === 'client-admin';
    },
    showDeleteButton() {
      const currentUser = store.getters.getUserState;
      const { user } = this;
      const arr = user.retainer?.filter(item => {
        return currentUser.retainer?.includes(item)
      });
      return arr.length > 0 && !(user.permission === 'admin' || user.permission === 'client-admin') || this.isSelf || currentUser.permission === 'admin';
    },
    deleteUserModalDescription() {
      const user = Object.assign({}, this.user);
      return `<span class="block mb-1">Name: <span class="font-medium">${user.firstName} ${user.lastName}</span></span>
                <span class="block">Email: <span class="font-medium">${user.email}</span></span>`;
    }

  },
  async mounted() {
    this.getUserData()
  },
  methods: {
    close() {
      this.showModal = false
    },
    // isActiveLabel(client) {
    //   return client && this?.managedClient?.includes(client?.clientId);
    // },
    handleClick() {
      this.showModal = !this.showModal;
    },
    addUser(id) {

      // Check if `this.details.users` is undefined
      if (!this.managedClient) {
        this.managedClient = []; // Initialize `users` as an empty array
      }

      this.managedClient.push(id);
    },
    removeUser(uid) {
      // Check if `this.managedClient` is undefined or the specified userType is undefined
      if (!this.managedClient) {
        return;
      }

      const index = this.managedClient.findIndex(user => user === uid);
      if (index !== -1) {
        this.managedClient.splice(index, 1);
      } else {
        console.log(`User with UID ${uid} not found.`);
      }
    },
    async getUserData() {
      const auth = getAuth();
      const once = auth.onAuthStateChanged(user => {
        if (user) {
          this.authUserUID = user.uid;
        }
      });

      once();

      await initAllProjects();

      const db = getDatabase();
      const userRef = ref(db, 'users/' + this.userid);
      axios({
        method: 'get',
        url: `${this.$root.apiBaseUrl}/client/all`,
        headers: {
          'authorization': auth.currentUser.accessToken,
          'timezone': this.$root.apiTimezone
        },
      }).then(res => {
        this.allTeams = res.data;
        this.allTeams.unshift({
          name: 'Internal User',
          everhourClientId: 'internal-user'
        });
      }).catch(err => {
        console.log(err)
      });

      const clientsRef = ref(db, `/clients`);

      if (this.user.team === 'internal-user') {
        const retainers = store.getters.getAllProjects.map(item => {
          return {
            label: item.projectName,
            value: item.id
          }
        });
        this.allRetainers = retainers;
      }
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (!data.retainer) {
          data.retainer = [];
        }

        this.user = data;
        const editUser = this.editUser;
        this.editUser = { ...editUser, ...data };

        if (data.managedClient) {
          this.managedClient = Object.keys(data.managedClient);
        }


        const currentToken = auth.currentUser.accessToken;
        axios
          .get(`${this.$root.apiBaseUrl}/client/all`, {
            headers: {
              authorization: currentToken,
            },
          })
          .then((res) => {
            this.allTeams = res.data;
            this.allTeams.unshift({
              name: 'Internal User',
              everhourClientId: 'internal-user',
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
      onValue(clientsRef, (snapshot) => {
          if (snapshot.exists()) {
              const clientsObject = snapshot.val()
              this.firebaseRetainers = Object.keys(clientsObject)
                  .filter(key => !clientsObject[key].archived)
                  .map(key => {
                      return {
                          ...clientsObject[key],
                          clientId: key
                      }
                  });

              this.isLoading = false;
          } else {
              console.log("No data available");
              return {}
          }
      });
    },
    // async getStats() {
    //         const db = getDatabase();
    //         const clientsRef = ref(db, `/clients`);

    //     },
    multiSelectRetainerModel() {
      const { allRetainers, user } = this;
      const editUserRetainers = user?.retainer.map(item => {
        let obj = {};
        allRetainers.forEach(retainer => {
          if (retainer.value === item) {
            obj = retainer;
          }
        });
        return Object.keys(obj).length > 0 ? obj : null;
      }).filter(item => {
        return item;
      });
      this.editUser.retainer = editUserRetainers;
    },
    roleValuesToLabel(value) {
      let role = allRoles.filter(item => {
        return item.value == value
      });
      return role[0] ? role[0].label : '';
    },
    handleEditField(field) {
      this.isEdit = true;
      this.editField = field;
      const defaultFields = {
        roleTitle: '',
        phone: '',
        accountSettings: [],
      };
      this.editUser = { ...defaultFields, ...this.user };
      this.showNotification = false;
      // this.getRetainerProjects();
    },
    handleCancelEdit() {
      this.isEdit = false;
      this.editField = "";
    },
    // getRetainerProjects() {
    //     if (this.editUser.team) {
    //         if (this.editUser.team !== 'internal-user') {
    //             axios.get(`${this.$root.apiBaseUrl}/project/list/${this.editUser.team}`).then(res => {
    //                 if (res.data) {
    //                     const retainers = res.data;
    //                     if (retainers.length > 0) {
    //                         const allRetainersArr = retainers.map(item => {
    //                             return {
    //                                 value: item.id,
    //                                 label: item.projectName
    //                             }
    //                         })
    //                         this.allRetainers = allRetainersArr;
    //                         this.editUser.retainer = [];
    //                         this.multiSelectRetainerModel()
    //                     } else {
    //                         this.allRetainers = []
    //                     }
    //                 } else {
    //                     this.allRetainers = []
    //                     this.editUser.retainer = []
    //                 }
    //             }).catch(err => {
    //                 console.log(err)
    //             });
    //         } else {
    //             this.allRetainers = store.getters.getAllProjects.map(item => {
    //                 return {
    //                     label: item.projectName,
    //                     value: item.id
    //                 }
    //             });
    //             this.multiSelectRetainerModel()
    //         }
    //     } else {
    //         this.allRetainers = [];
    //         this.editUser.team = "";
    //         this.editUser.retainer = [];
    //     }
    // },
    handleUserUpdate() {
      this.firstNameError = this.editUser.firstName ? '' : 'First name is required.';
      this.lastNameError = this.editUser.lastName ? '' : 'Last name is required.';

      if (this.firstNameError || this.lastNameError) {
          return;
      }

      const { firstName, lastName, email, client, permission, accountSettings, userCanLogin } = this.editUser;
      const profileImage = this.editUser.profileImage ? this.editUser.profileImage : '';

      let managedClientObject = {};
      this.managedClient.forEach(clientId => {
        managedClientObject[clientId] = true;
      });

      const retainer = this.editUser.retainer.map(item => {
        return item.value
      });
      const roleTitle = this.editUser.roleTitle ? this.editUser.roleTitle : '';
      const phone = this.editUser.phone ? this.editUser.phone : '';

      const oldUser = { ...this.user };

      const app = getApp();
      const functions = getFunctions(app);

      this.submitting = true;

      const oldClient = this.user.client;

      const sendUserUpdate = httpsCallable(functions, "updateUserDetails");
      sendUserUpdate({ uid: this.uid, data: { firstName, lastName, email, phone, roleTitle, client, retainer, permission, profileImage, accountSettings, userCanLogin, managedClient: managedClientObject } }).then(result => {
        LogAuditLog({
          type: "User",
          object: "Contact",
          action: "edit",
          data: {
            id: this.uid,
            firstName,
            lastName,
            email,
            phone,
            roleTitle,
            client,
            clientId: client,
            retainer,
            permission,
            profileImage,
            accountSettings,
            userCanLogin,
            managedClient: managedClientObject
          }

        })
        // updateRetainerUserDb(this.uid, { firstName, lastName, email, phone, roleTitle, client, retainer, permission, profileImage, accountSettings, userCanLogin, managedClient: managedClientObject }, oldUser).then(res => {
        //   this.isSelf ?? store.commit('updateUserState', { firstName, lastName, email, phone, roleTitle, client, retainer, permission, profileImage, accountSettings, userCanLogin, managedClient: managedClientObject });
        //   this.isEdit = false;
        //   this.editField = "";
        //   this.user = { ...this.editUser };
        //   this.user.retainer = retainer;
        // });

        this.showNotification = true;
        this.submitting = false;

        const oldClientRef = ref(getDatabase(), `clients/${oldClient}/`);
        const clientRef = ref(getDatabase(), `clients/${client}/`);

        if(this.permission === 'admin') {
          update(clientRef, {
            [`accountManagers/${this.uid}`]: true
          });
        }
        if (oldClient !== client) {
          update(oldClientRef, {
            [`users/${this.uid}`]: null
          }).then(() => {
            update(clientRef, {
              [`users/${this.uid}`]: true
            });
          });
        } else {
          update(clientRef, {
            [`users/${this.uid}`]: true
          });
        }

        // Update the users field in the client's data
        update(clientRef, {
          [`users/${this.uid}`]: true
        });

      }).catch(err => {
        console.log(err)
        this.submitting = false;
      });
    },
    handleDeleteModalClose() {
      this.deleteModalActive = false;
      this.selectedUser = {}
    },
    async handleDeleteUserModalConfirm() {
      this.submitting = true;
      this.$notify({
        title: "User deleted",
        text: "User successfully deleted",
        duration: 5000,
      });
      await deleteUser(this.uid, this.user);
      this.deleteModalActive = false;
      this.submitting = false;
      this.$router.go(-1)
    },   
    async handleUploadImage() {
      const file = this.$refs.profilePicUpload.files[0];
      const fullPath = `profile-pic/${this.uid}/${file.name}`;
      this.submitting = true;
      // Upload to storage
      const storage = getStorage();
      const storageReference = storageRef(storage, fullPath);
      const snapshot = await uploadBytes(storageReference, file);
      const imageUrl = await getDownloadURL(snapshot.ref);

      const oldUser = { ...this.user };
      const { firstName, lastName, email, phone, roleTitle, team, retainer, permission } = this.user;

    },
    handleDeleteModalClose() {
      this.deleteModalActive = false;
      this.selectedUser = {}
    },
    handleDeleteUserModal() {
      this.deleteModalActive = true;
      this.selectedUser.userId = this.uid;
      this.selectedUser.user = this.user;
      //document.body.classList.add('overflow-hidden');
    },
    async handleUploadImage() {
      const file = this.$refs.profilePicUpload.files[0];
      const fullPath = `profile-pic/${this.uid}/${file.name}`;
      this.submitting = true;
      // Upload to storage
      const storage = getStorage();
      const storageReference = storageRef(storage, fullPath);
      const snapshot = await uploadBytes(storageReference, file);
      const imageUrl = await getDownloadURL(snapshot.ref);

      const oldUser = { ...this.user };
      const { firstName, lastName, email, phone, roleTitle, team, retainer, permission } = this.user;

      const app = getApp();
      const functions = getFunctions(app);

      const sendUserUpdate = httpsCallable(functions, "updateUserDetails");
      sendUserUpdate({ uid: this.uid, data: { firstName, lastName, email, phone, roleTitle, team, retainer, permission, profileImage: imageUrl, accountSettings } }).then(result => {
        // updateRetainerUserDb(this.uid, { firstName, lastName, email, phone, roleTitle, team, retainer, permission, profileImage: imageUrl, accountSettings }, oldUser).then(res => {
        //   this.img = imageUrl;
        //   store.commit('updateUserProfileImage', imageUrl);
        // });
        this.showNotification = true;
        this.submitting = false;
      });
    },
    convertDateString(ds, monthYear) {
      if (ds) {
        var options = monthYear ? { month: 'long', year: 'numeric' } : { month: 'short', day: 'numeric' };
        var date = new Date(ds);
        return date.toLocaleString('en-US', options)
      }
      return '—'
    },
    handleCheckboxUpdate(value) {
      let settings = this.editUser.accountSettings;
      if (settings.filter(a => a === value).length > 0) {
        this.editUser.accountSettings = settings.filter(a => a !== value);
      } else {
        let arr = settings;
        arr.push(value);
        this.editUser.accountSettings = arr;
      }
    },
    handleCanLoginUpdate() {
      this.editUser.userCanLogin = !this.editUser.userCanLogin;

    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
@import "vue-select/dist/vue-select.css";
</style>

<style scoped>
:deep() {
  --vs-actions-padding: 16px;
}
</style>

<style lang="scss">
.multiselect-dropdown {
  margin-right: -1px;
}
</style>
<script setup>
    import { getDatabase, get, child, ref, onValue, update } from "firebase/database";
    import { getAuth } from "firebase/auth";
    import axios from "axios";
    import { isProxy, toRaw } from 'vue';

    import getRetainerUsers from "@/helpers/GetRetainerUsers";
    import ConfirmationDialog from "@/components/containers/ConfirmationDialog.vue";

    import RetainerListingItem from '@/components/RetainerListingItem.vue';
    import FilterArrow from '~icons/my-icons/filter-arrow';

	import LogAuditLog from "@/helpers/logAuditLog";
    import NoResultFound from '@/components/objects/NoResultFound.vue';
</script>

<template>
    <div class="border border-grey-200 rounded-lg">
        <table class="w-full border-collapse rounded-lg">
            <thead class="border-none bg-grey-50 rounded-lg sticky top-0 z-50">
                <tr class=" border-b border-grey-200 rounded-lg">
                    <th class="w-16 rounded-tl-lg"></th>
                    <th class="text-left text-xs font-normal align-middle pr-6  w-[420px]">
                        <div class="border-r border-gray-200 py-4 px-2">
                            <button class="flex" @click="toggleSortOrder">
                                <strong class="block mr-1">Client</strong> 
                                <FilterArrow :class="ascending ? '' : 'rotate-180'" />
                            </button>
                        </div>
                    </th>
                    <th class="text-left text-xs font-normal align-middle py-4 ml-6 w-[200px]">
                        <strong class="block">Client meeting</strong>
                        <span>Previous</span>
                    </th>
                    <th class="text-left text-xs font-normal pr-6 align-middle">
                        <div class="border-r border-gray-200 py-4 px-2 pt-8">
                            <span>Next</span>
                        </div>
                    </th>
                    <th class="text-left text-xs font-normal align-bottom py-4 px-2 w-[88px]">
                        <strong class="block">Rate</strong>
                        <span>Amount</span>
                    </th>
                    <th class="text-left text-xs font-normal align-middle w-[120px]">
                        <div class="border-r border-gray-200 py-4 px-2 pt-8">
                            <span>Rate modified</span>
                        </div>
                    </th>
                    <th class="text-center text-xs font-normal align-middle py-4 px-2 w-32">
                        <strong class="block">Happiness</strong>
                    </th>
                    <th class="rounded-tr-lg text-left text-xs font-normal align-middle py-4 px-2 w-12"></th>
                </tr>
            </thead>
            <tbody>
                <RetainerListingItem
                    v-for="item in sortedClients"
                    @archiveClient="promptArchiveClient"
                    :key="item.lastEditDate"
                    :default-expanded="item.clientId == $route.query.viewing"
                    :prop-data="item"
                    :retainers-list="allRetainers"
                    :default-retainers="item.retainers"
                    @editClient="openEditClient"
                />
            </tbody>
        </table>
        <NoResultFound :counts="retainerData.length" :searchTerm="searchTerm" :handleFullReset="handleFullReset" pageName="clients" />
    </div>
    <ConfirmationDialog
        :isActive="showArchivePrompt"
        :headerText="archiveState ? 'Archive this client wont permanently delete the data, it is recoverable within the &quot; View Archived &quot; section. Are you sure you want to archive this client?' : 'Are you sure you want to unarchive this client?'"
        descriptionText=""
        :confirmButtonText="archiveState ? 'Archive client' : 'Unarchive client'"
        @handleModalClose="closeArchivePrompt"
        @handleModalConfirm="archiveClient()"
    />
    <router-view></router-view>
</template>

<script>

    export default {
        props: ['retainerData', 'showEditClient', 'showArchived', 'searchTerm'],
        emits: ["parentClose"],
        data: function() {
            return {
                showArchivePrompt: false,
                retainer: this.retainerData ? this.retainerData : {},
                selectedData: null,
                editClientMenuOpen: false,
                retainersList: [],
                archiveClientId: null,
                archiveState: false,
                retainerUsers: [],
                ascending: true
            }
        }, 
        computed: {
            retainers() {
                // Deep clone array
                const retainerArr = JSON.parse(JSON.stringify(this.retainerData));
                // This might need to be adjusted later (re: contacts)
                const retainerUsersArr = this.retainerUsersList;
                const formattedArray = retainerArr.map(item => {
                    const allRetainerId = Object.keys(item.retainers || {})?.map(retainer => { return item.retainers?.[retainer]?.retainerId || retainer }) || [];
                    
                    const usersArray = [];
                    allRetainerId.forEach(retainerId => {
                        usersArray.push(retainerUsersArr[retainerId])
                    });
                    return {
                        ...item,
                        contacts: usersArray
                    }
                });


                return formattedArray;
            },
            allRetainers() {
                return this.retainersList;
            },
            retainerUsersList() {
                return JSON.parse(JSON.stringify(this.retainerUsers));
            },
            sortedClients() {
                const sortedArray = [...this.retainers];

                if (this.ascending) {
                    sortedArray.sort((a, b) => {
                        const clientA = a.client;
                        const clientB = b.client;
                        if (clientA && clientB) {
                        return clientA.clientName.localeCompare(clientB.clientName);
                        }
                        return 0;
                    });
                } else {
                    sortedArray.sort((a, b) => {
                        const clientA = a.client;
                        const clientB = b.client;
                        if (clientA && clientB) {
                        return clientB.clientName.localeCompare(clientA.clientName);
                        }
                        return 0;
                    });
                }

                const filteredClients = sortedArray.filter(client => {
                    return this.showArchived ? true : !client.archived;
                });

                return this.ascending ? filteredClients : filteredClients.reverse();
            }
        },
        async mounted() {
            await this.getRetainerList();
            await this.fetchRetainerUsers()
        },
        methods: {
            handleFullReset() {
                this.$emit('handleFullReset');
            },
            closeArchivePrompt() {
                this.archiveClientId = null;
                this.archiveState = null;
                this.showArchivePrompt = false;
            },
            promptArchiveClient(promptRetainerId, promptArchiveState) {
                this.archiveClientId = promptRetainerId;
                this.archiveState = promptArchiveState;
                this.showArchivePrompt = true;
            },
            archiveClient() {
                const db = getDatabase();
                const dbRef = ref(getDatabase());
                const auth = getAuth();
                const user = auth.currentUser;

                let updates
                if (this.archiveState) {
                    updates = {
                        archived: true,
                        archivedBy: user.uid,
                        archivedDate: new Date().toISOString()
                    }
                    LogAuditLog({
                        type: "Client",
                        object: "Client",
                        action: "archive",
                        data: {id: this.archiveClientId}
                    })
                } else {
                    updates = {
                        archived: false,
                        archivedBy: null,
                        archivedDate: null
                    }
                    LogAuditLog({
                        type: "Client",
                        object: "Client",
                        action: "unarchive",
                        data: {id: this.archiveClientId}
                    })
                }

                update(child(dbRef, `clients/${this.archiveClientId}`), updates)
                .then(() => {
                    this.closeArchivePrompt()
                })
                .catch((error) => {
                    console.log("Error updating client:", error);
                    this.closeArchivePrompt()
                });
            },
            openEditClient(data) {
                this.selectedData = data ? data : null;
                this.editClientMenuOpen = true;
            },
            async getRetainerList() {
                const auth = getAuth();
                await axios({
                    method: 'get',
                    url: `${this.$root.apiBaseUrl}/project/list`,
                    headers: {
                        'authorization': auth.currentUser.accessToken,
                        'timezone': this.$root.apiTimezone
                    }
                }).then(res => {
                    this.retainersList = isProxy(res.data) ? toRaw(res.data): res.data || [];
                    this.$store.commit('updateAllRetainers', this.retainersList);
                }).catch(err => {
                    console.error('An error occurred while fetching retainer list:', err);
                });
            },
            async fetchRetainerUsers() {
                this.retainerUsers = await getRetainerUsers();
            },
            toggleSortOrder() {
                this.ascending = !this.ascending;
            }
        }
    };

</script>


<style lang="scss" scoped>
    
</style>
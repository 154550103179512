<script setup>
    import PermissionsHelpers from '@/helpers/PermissionHelpers';
    import moment from "moment";
    import SimpleShortDate from '@/helpers/SimpleShortDate';
    import Tooltip from '@/components/objects/Tooltip.vue';
	import yearMonthFormat from '@/helpers/YearMonthFormat';
    import Info from '~icons/my-icons/Info';
    import { getDatabase, ref, onValue } from "firebase/database";
</script>


<template>
    <router-link :to="{ name: 'Beta Dashboard Reports Listing', params: { date: yearMonthFormat(tileData.title) || 'latest', retainerId: retainerId || $route.params.retainerId, clientId: clientId || $route.params.retainerId } }" @click="toListing(tileData.title)" v-if="!renderLink">
        <div class="p-6 bg-white text-left rounded-md border-bd border h-full hover:drop-shadow-lg">
            <div class="flex justify-between items-center mb-5">
                <h4 class="text-lg font-bold">{{tileData.title}}</h4>
                <div :class="class" class="border bg-grey-50 border-grey-200 rounded px-3 py-1.5 text-xs flex items-center justify-center">
                    View
                </div>
            </div>
            <div class="h-2 mt-4 mb-5 bg-grey-100 overflow-hidden rounded-full flex items-center">
                <span :class="getOverTime ? 'rounded-l-full' : 'rounded-full'" ref="usedBar" class="h-full bg-[#70BC41] inline-flex items-center justify-end text-xs font-bold transition-all duration-700 delay-300">
                </span>
                <span v-if="getOverTime !== null" ref="extraBar" class="flex-shrink-0 h-full rounded-r-full bg-[#EF4E41] inline-flex items-center text-xs font-bold transition-all duration-700 delay-700">
                </span>
            </div>
            <div v-if="remainingTimeCompute" class="flex items-center justify-between mb-2.5">
                <div class="text-xs font-bold">Remaining</div>
                <div
                    class="text-center text-xs py-1 px-2 border font-bold rounded flex justify-center items-center"
                    :class="remainingTimeCompute.value >= 0 ? 'border-grey-200 text-purple-900' : 'bg-red-50 border-red-100 text-red-500'"
                >
                    {{remainingTimeCompute.displayValue}}

                    <Tooltip
                        v-if="showOverspend"
                        class="flex"
                        position="top"
                        >
                        <Info class="ml-1 w-4 h-4 shrink-0" />
                        <template v-slot:htmlContent>
                            <div class="text-center">
                            <span>Includes <span class="font-bold">{{ overspendHours }}hrs (${{ overspendPrice }})</span> invoiced to client <br /> for overhours.</span>
                            <div v-for="(invoice, invoiceId) in tileData.overspendInvoices" :key="invoiceId">
                                <div class="my-1 divide-y space-y-1 divide-purple-500">
                                <div class="text-xs font-bold">{{ invoice[1].invoiceNumber }} ({{ SimpleShortDate(invoice[1].effectiveDate) }})</div>
                                <div class="text-xs">{{ invoice[1].description }}</div>
                                </div>
                            </div>
                            </div>
                        </template>
                    </Tooltip>
                </div>
            </div>
            <ul class="text-sm">
                <template
                    v-for="item in renderData[0]?.listData"
                    :key="item[0]?.value"
                >
                    <li class="flex text-xs px-3 py-1.5 items-center border-b border-grey-100 border-solid justify-between last:border-0" 
                        v-if="PermissionsHelpers.isWebcodaAdmin() || item.label !== 'Unpublished time-entries'"
                    >
                        <span :class="[item?.label.includes(`Rollover to`) ? 'font-bold': '']">{{item.label}}</span>
                        <span :class="[item?.label.includes(`Rollover to`) ? 'font-bold': '', (item?.label == 'Unpublished time-entries' && item.value > 0) && 'text-warning']">
                            {{ formatValue(item.label, item.value) }}
                        </span>
                    </li>
                </template>
            </ul>
            <div class="flex text-xs px-3 py-1.5 items-center border-y border-grey-100 border-solid justify-between"
                v-if="PermissionsHelpers.isWebcodaAdmin()"
            >
                <span>Hours lost from rollover cap</span>
                <span>{{tileData.listData.filter(item => item.itemName === 'LostHours')[0].itemValue}}</span>
            </div>
        </div>
    </router-link>
    <div class="p-6 bg-white border border-bd text-left rounded-md" v-else>
        <div class="flex justify-center items-center mb-5">
            <h4 class="text-lg font-bold">{{tileData.title}}</h4>
        </div>
        <div class="h-2 mt-4 mb-5 bg-grey-100 overflow-hidden rounded-full flex items-center">
            <span :class="getOverTime ? 'rounded-l-full' : 'rounded-full'" ref="usedBar" class="h-full bg-[#70BC41] inline-flex items-center justify-end text-xs font-bold transition-all duration-700 delay-300">
            </span>
            <span v-if="getOverTime !== null" ref="extraBar" class="flex-shrink-0 h-full rounded-r-full bg-[#EF4E41] inline-flex items-center text-xs font-bold transition-all duration-700 delay-700">
            </span>
        </div>
        <ul class="text-sm">
            <template v-for="item in renderData[0]?.listData" 
                :key="item[0]?.value">
            <li class="flex text-xs px-3 py-1.5 items-center border-b border-grey-100 border-solid justify-between last:border-0 last:font-bold" 
            v-if="PermissionsHelpers.isWebcodaAdmin() || item.label !== 'Unpublished time-entries'"
            >
                <span>{{item.label}}</span>
                <span>{{item.value}}</span>
            </li>
        </template>
        </ul>
        <div class="flex text-xs px-3 py-1.5 items-center border-y border-grey-100 border-solid justify-between"
            v-if="PermissionsHelpers.isWebcodaAdmin()"
        >
            <span>Hours lost from rollover cap</span>
            <span>{{tileData.listData.filter(item => item.itemName === 'LostHours')[0].itemValue}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'disableLink', 'class', 'retainerId', 'clientId'],
        data() {
            return {
                tileData: this.data,
                renderData: [],
                renderLink: this.disableLink,
                overspendInvoices: {}
            }
        },
        computed: {
            remainingTimeCompute() {
                return {
                    value: parseFloat(this.tileData.listData.filter(item => item.itemName === 'Remaining')[0].itemValue.replace('hrs', '')),
                    displayValue: this.tileData.listData.filter(item => item.itemName === 'Remaining')[0].itemValue
                }
            },
            getBillableTime() {
                const billableTime = this.convertHrStringToNum(this.tileData.listData.filter(item => item.itemName === 'Billable')[0].itemValue);
                const remainingTime = this.convertHrStringToNum(this.tileData.listData.filter(item => item.itemName === 'Remaining')[0].itemValue);
                const allowanceTime = this.convertHrStringToNum(this.tileData.listData.filter(item => item.itemName === 'Allowance')[0].itemValue);
                if(remainingTime >= 0) {
                    return billableTime
                } else {
                    return allowanceTime
                }
            },
            getOverTime() {
                let remainingTime = this.convertHrStringToNum(this.tileData.listData.filter(item => item.itemName === 'Remaining')[0].itemValue);
                return remainingTime < 0 ? Math.abs(remainingTime) : null
            },
            getPrevMonth() {
                const currentDate = new Date(`1 ${this.tileData.title}`);
                currentDate.setMonth(currentDate.getMonth() - 1);
                return currentDate.toLocaleString('default', { month: 'long' });;
            },
            getNextMonth() {
                const currentDate = new Date(`1 ${this.tileData.title}`);
                currentDate.setMonth(currentDate.getMonth() + 1);
                return currentDate.toLocaleString('default', { month: 'long' });;
            },
            showOverspend() {
                return !(this.tileData?.overspendInvoices === undefined || this.tileData?.overspendInvoices.length < 1);
            },
            overspendHours() {
                if(this.tileData?.overspendInvoices?.length < 1) return 0;
                return Object.keys(this.tileData?.overspendInvoices)?.reduce((acc, key) => {
                    return acc + parseFloat(this.tileData?.overspendInvoices[key][1]?.hoursBilled)
                }, 0)
            },
            overspendPrice() {
                if(this.tileData?.overspendInvoices?.length < 1) return 0;
                return Object.keys(this.tileData?.overspendInvoices)?.reduce((acc, key) => {
                    return acc + parseFloat(this.tileData?.overspendInvoices[key][1]?.value)
                }, 0)
            },
            
        },
        mounted() {        
            const db = getDatabase();

			const overspendInvoicesRef = ref(db, `/overspendInvoices/${this.retainerId}`);
				
			onValue(overspendInvoicesRef, snapshot => {
				if (snapshot.val()) {
					this.overspendInvoices = snapshot.val();
                    console.log(this.overspendInvoices)

                    this.tileData.overspendInvoices = this.overspendInvoices ?  Object.entries(this.overspendInvoices).filter(([invoiceKey, invoiceData]) => 
                    moment(invoiceData.effectiveDate).format('MMMM yyyy') === this.tileData.title) :  [];
				} else {
					this.overspendInvoices = null
				}

                console.log(this?.$route?.params)
			});    
            this.update();
        }, 
        methods: {
            convertHrStringToNum(string) {
                let str = string.split('hrs')[0];
                return parseFloat(str);
            },
            toListing(date) {
                this.$router.push({
                    name: 'Beta Dashboard Reports Listing',
                    params: {
                        retainerId: this.retainerId || this.$route.params.retainerId,
                        date: yearMonthFormat(date)
                    }
                });
            },
            update() {
                this.renderData = this.tileData.listData.map((item) => {
                    return {
                        listData: [
                            {
                                label: 'Tasks',
                                value: this.tileData.listData.filter(item => item.itemName === 'Tasks')[0].itemValue
                            },
                            {
                                label: 'Unpublished time-entries',
                                value: this.tileData.listData.find(item => item.itemName === 'UnPublished').itemValue || '-'
                            },
                            {
                                label: 'Monthly hours',
                                value: this.tileData.listData.filter(item => item.itemName === 'Retainer')[0].itemValue
                            },
                            {
                                label: `Hours brought forward from ${this.getPrevMonth}`,
                                value: this.tileData.listData.filter(item => item.itemName === 'Rollover')[0].itemValue
                            },
                            {
                                label: 'Total allowance',
                                value: this.tileData.listData.filter(item => item.itemName === 'Allowance')[0].itemValue
                            },
                            {
                                label: 'Billable',
                                value: this.tileData.listData.filter(item => item.itemName === 'Billable')[0].itemValue
                            },
                            {
                                label: `Rollover to ${this.getNextMonth}`,
                                value: this.tileData.listData.filter(item => item.itemName === 'RolloverToNext')[0].itemValue
                            },
                        ]
                    }
                });

                let billableTimeObj = this.tileData.listData.filter(item => item.itemName === 'Billable');
                let remainingTimeObj = this.tileData.listData.filter(item => item.itemName === 'Remaining');

                let allowanceTimeObj = this.tileData.listData.filter(item => item.itemName === 'Allowance');
                let billableTime = this.convertHrStringToNum(billableTimeObj[0].itemValue);
                let remainingTime = this.convertHrStringToNum(remainingTimeObj[0].itemValue);
                if(remainingTime < 0 && this.getOverTime !== null) {
                    setTimeout(() => {
                        this.$refs.extraBar.style.cssText = `width: ${Math.abs(remainingTime) / billableTime * 100}%`;
                        billableTime = billableTime + remainingTime;
                    }, 301);
                } 
                setTimeout(() => {
                    if (this.$refs.usedBar) {
                        if((billableTime / this.convertHrStringToNum(allowanceTimeObj[0].itemValue) * 100) <= 100) {
                            this.$refs.usedBar.style.cssText = `width: ${billableTime / this.convertHrStringToNum(allowanceTimeObj[0].itemValue) * 100}%`;
                        } else {
                            this.$refs.usedBar.style.cssText = `width: ${(this.convertHrStringToNum(allowanceTimeObj[0].itemValue) / billableTime) * 100}%`;
                        }
                    }
                }, 1)
            },
            formatValue(label, value) {
                if (label === 'Tasks' || label === 'Unpublished time-entries' || label === 'Total allowance' || label === 'Monthly hours') {
                    return value;
                } else if (this.isPositive(value)) {
                    return '+' + value;
                } else {
                    return value;
                }
            },
            isPositive(value) {
                const numericValue = parseFloat(value);
                return !isNaN(numericValue) && numericValue >= 0;
            }
        },
        watch: {
            '$props.data': function(newVal) {
                this.tileData = newVal;
                this.update();
            }
        },
    }
</script>


<style lang="scss">

</style>
<script setup>

    import { getAuth } from "firebase/auth";
    import { getDatabase, ref, update, onValue } from "firebase/database";
    import axios from 'axios';
    import store from '@/store';

    import Container from '@/components/containers/Container.vue';
    import Header from '@/components/objects/Header.vue';
    import Loading from '@/components/containers/Loading.vue';
    import ArrowLeft from '~icons/my-icons/arrow-left';
    import IconClose from '~icons/my-icons/cross';
    import PermissionsHelpers from '@/helpers/PermissionHelpers';
    import ProjectValueToLabel from '@/helpers/ProjectValueToLabel';

	import { Skeletor } from "vue-skeletor";

</script>

<template>
  <div class="relative">
    <div class="py-9 px-8 bg-white border-b border-solid border-bd">
        <Container>
            <div class="w-full flex justify-between items-center text-3xl font-bold">
                <router-link :to="{ name: 'Beta Dashboard Reports Listing', params: { retainerId: $route.params.retainerId, date: $route.params.date }}">
                    <span class="inline-flex items-center">
                        <ArrowLeft class="mr-8 text-xl" />
                        <span>Send report</span>
                    </span>
                </router-link>
            </div>
        </Container>
    </div>
    <div class="pt-20 pb-20">
        <Container>
            <div class="max-w-[840px] mx-auto border border-solid border-bd rounded-lg bg-white u-content-wrapped">
                <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                    <div class="flex items-center px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold">
                            Subject
                        </div>
                        <div class="text-grey-900 flex-1">
                            <strong>
                                {{ProjectValueToLabel($route.params.retainerId)}} - {{$route.params.date}} Retainer Report
                            </strong>
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 pb-4 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-2">
                            To
                        </div>
                        <div class="text-grey-900 flex-1">
                            <span class="block mb-3">
                                <input 
                                    type="email"
                                    v-model="recipientInput"
                                    @keyup.enter="evt => addRecipient(evt)"
                                    class="relative inline-flex items-center px-3 py-2.5 text-sm text-grey-600 border border-grey-300 rounded mr-2 last:mr-0 w-full"
                                    placeholder="Enter email and press enter to add" />
                                <p v-if="recipientExistsError" class="text-red-500 text-xs mt-1">Email entered already in recipient list.</p>
                                <p v-if="recipientEmptyError" class="text-red-500 text-xs mt-1.5">Recipient list cannot be empty.</p>
                            </span>
                            <span 
                                v-for="user in reportRecipients" 
                                :key="user.uid" 
                                class="relative inline-flex items-center mb-2 px-3 py-1.5 text-xs text-grey-600 bg-grey-50 border border-grey-300 rounded mr-2 last:mr-0">
                                {{user.label}}
                                <button @click="removeRecipient(user.email)">
                                    <IconClose class="ml-3 w-3" />
                                    <span class="sr-only">
                                        Remove
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                            Retainer details
                        </div>
                        <div class="text-grey-900 flex-1">
                            <div class="mb-1 flex last:mb-0">
                                <div class="w-48 flex-shrink-0 mr-3">Used:</div>
                                <div v-if="!loadingSelectedMonthTile">{{selectedMonthTileData.billableHours || 0}}hrs</div>
                                <Skeletor v-else class="rounded" width="32" height="20" />
                            </div>
                            <div class="mb-1 flex last:mb-0">
                                <div class="w-48 flex-shrink-0 mr-3">Remaining:</div>
                                <div v-if="!loadingSelectedMonthTile">{{selectedMonthTileData.remainingHours}}hrs</div>
                                <Skeletor v-else class="rounded" width="48" height="20" />
                            </div>
                            <div class="mb-1 flex last:mb-0">
                                <div class="w-48 flex-shrink-0 mr-3">Rollover to {{nextMonth}}:</div>
                                <div v-if="!loadingSelectedMonthTile">{{selectedMonthTileData.rolloverHours}}hrs</div>
                                <Skeletor v-else class="rounded" width="48" height="20" />
                            </div>
                            <div class="mb-1 flex last:mb-0">
                                <div class="w-48 flex-shrink-0 mr-3">Tasks:</div>
                                <div v-if="!loadingSelectedMonthTile">{{selectedMonthTileData.tasks}}</div>
                                <Skeletor v-else class="rounded" width="24" height="20" />
                            </div>
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                            Message
                        </div>
                        <div class="text-grey-900 flex-1">
                            <textarea @input="evt => reportMessage = evt.target.value" :value="reportMessage" class="w-full border border-bd rounded p-3 min-h-[180px]" placeholder="Add message"></textarea>
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                            Notes
                        </div>
                        <div class="text-grey-900 flex-1">
                            <textarea @input="evt => reportNotes = evt.target.value" :value="reportNotes"  class="w-full border border-bd rounded p-3 min-h-[180px]" placeholder="Add note"></textarea>
                        </div>
                    </div> 
                    <div class="flex items-center justify-end px-10 py-6 border-b border-grey-200 last:border-none">
                        <router-link :to="{name: 'Beta Dashboard Reports Listing', params: { date: retainer ? retainer.reportMonth : 'latest' }}" class="text-blue-500 mr-6">
                            Cancel
                        </router-link>
                        <button 
                            class="ml-3 bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center disabled:text-grey-300 disabled:bg-grey-100"
                            @click="handleSendReport()"
                            :disabled="reportRecipients.length == 0" 
                        >
                            Send
                        </button>
                    </div> 
                </Loading>
            </div>
        </Container>
    </div>
  </div>
</template>

<script>

  export default {
    props: ['retainerData'],
    data() {
      return {
          appSettings: {},
          retainer: null,
          isSubmitting: false,
          reportMessage: '',
          reportNotes: '',
          reportRecipients: [],
          recipientInput: "",
          recipientExistsError: false,
          recipientEmptyError: false,
          selectedMonthTileData: [],
          loadingSelectedMonthTile: true,
      }
    },
    mounted() {
        this.getMonthTileData();

        this.getRetainerUsers();

        if(store.getters.getAppSettings) {
            this.appSettings = {...store.getters.getAppSettings}
        }
    },
    computed: {
        nextMonth() {
            const now = new Date(this.$route.params.date)
            now.setMonth(now.getMonth() + 1);
            return now.toLocaleString('en-us', { month: 'long' })
        },
        getReportSubject() {
            const { retainer } = this;

            if(!retainer) {
                return ''
            }
            return `${retainer.retainerName.trim()} - ${retainer.reportMonth.trim()} Retainer Report`
        }
    },
    methods: {
		async getMonthTileData() {
			this.loadingSelectedMonthTile = true
			const auth = getAuth();

			if(auth.currentUser) {
				const currentToken = auth.currentUser.accessToken;
				let currentFilterDate = new Date(`${this.$route.params.date}-1`);
				let month = currentFilterDate.getMonth() + 1;
				let year = currentFilterDate.getFullYear();

				const result = await axios.get(`${this.$root.apiBaseUrl}/Retainer/${this.$route.params.retainerId}/${year}/${month}`, {
					headers: {
						'authorization': currentToken,
						'timezone': this.$root.apiTimezone
					}
				}).then(res => {
					const selectedTile = res.data;

					return selectedTile;
				}).catch(err => {
					console.log(err);
				})
				this.selectedMonthTileData = result
				this.loadingSelectedMonthTile = false
			}
		},
        addRecipient(e) {
            this.recipientExistsError = false;
            const exists = this.reportRecipients.filter(user => user.email === e.target.value);
            if(exists.length === 0) {
                this.reportRecipients.push({
                    label: e.target.value,
                    email: e.target.value
                });
                this.recipientInput = "";
            } else {
                this.recipientExistsError = true;
            }
        },
        removeRecipient(email) {
            this.reportRecipients = this.reportRecipients.filter(user => user.email !== email);
        },
        getRetainerUsers() {
            const retainerId = this.$route.params.retainerId
            if(retainerId) {
                const db = getDatabase();
                const retainerUsersRef = ref(db, `/retainerUsers/${retainerId}/`);
                onValue(retainerUsersRef, snapshot => {
                    if(snapshot.val()) {
                        let data = Object.keys(snapshot.val()).map((key) => {
                            return {
                                uid: key,
                                ...snapshot.val()[key]
                            }
                        });
                        let clientUserArr = data.filter(user => {
                            return user.accountSettings && 
                                user.accountSettings.includes('receive-monthly-report') && 
                                user.permission !== 'admin' && 
                                user.permission !== 'webcoda-user' && 
                                user.lastLoginTime
                        });
                        this.reportRecipients = clientUserArr.map(user => {
                            return {
                                label: `${user.firstName} ${user.lastName}`,
                                email: user.email
                            }
                        });
                    }
                }, { onlyOnce: true });
            } else {
                alert('no retainer specified')
            }
        },
        handleSendReport() {

            const retainerId = this.$route.params.retainerId;
            this.recipientEmptyError = false;

            if(!retainerId) {
                alert('no retainer specified');
                return;
            }

            if(this.reportRecipients.length == 0) {
                this.recipientEmptyError = true;
                return;
            }

            const auth = getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {
                    this.isSubmitting = true;
					const currentToken = auth.currentUser.accessToken;
                    const activeMonth = new Date(this.$route.params.date)
					var req = {
						message:this.reportMessage,
						notes:this.reportNotes,
						used: this.selectedMonthTileData.billableHours,
                        rollover: this.selectedMonthTileData.rolloverHours,
                        remaining: this.selectedMonthTileData.remainingHours,
                        tasks: this.selectedMonthTileData.tasks,
                        subject: `${ProjectValueToLabel(this.$route.params.retainerId)} - ${this.$route.params.date} Retainer Report`,
                        projectId: retainerId,
                        year: activeMonth.getFullYear(),
                        month: activeMonth.getMonth() + 1,
                        url: `${window.location.origin}/dashboard/retainer/${this.$route.params.retainerId}/reports/listing/${this.$route.params.date}`,
                        emails: this.reportRecipients.map(s => s.email)
                    };
					
					axios.post(`${this.$root.apiBaseUrl}/email/report`, req, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						}
					}).then(res => {

                        const db = getDatabase();
                        const updates = {};
                        const retainerHistoryData = {
                            sentBy: user.displayName,
                            sentDate: new Date(),
                            recipients: this.reportRecipients
                        };

                        updates[`/retainerReportHistory/${this.$route.params.retainerId}/${activeMonth.getFullYear()}-${activeMonth.getMonth() + 1}`] = retainerHistoryData;
                        update(ref(db), updates).then(res => {
                            this.notification = 'Success';
                            this.isSubmitting = false;
                            this.$router.push({
                                name: 'Beta Dashboard Reports Listing',
                                params: { notificationType: 'sendReportSuccess', date: this.$route.params.date }
                             });
                        }).catch(err => {
                            console.log(err);
                            this.isSubmitting = false;
                        });
						
					}).catch(err => {
                        this.isSubmitting = false;
						console.log(err);
					});					
				}
			});
        }
    }
  }
</script>

<style lang="scss">
    
</style>

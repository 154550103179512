// helper.js
import { getAuth } from "firebase/auth";

export function authTokenUpdate(callback) {
  const auth = getAuth();
  const unsubscribe = auth.onAuthStateChanged(user => {
    if (user) {
      auth.currentUser.getIdTokenResult(true).then(tokenResult => {
        callback(tokenResult.claims ? tokenResult.claims : {});
      });
    }
  });
  return unsubscribe;
}
<script setup>

    import { getDatabase, get, child, ref, onValue, update } from "firebase/database";

    import HappinessRenderer from "@/components/objects/HappinessRenderer.vue";
    import { Skeletor } from "vue-skeletor";

	import { AlertTotal } from '@/helpers/AlertCounter';

    import { getAuth } from "firebase/auth";
    
	import RetainersNeedAttention from '@/helpers/RetainersNeedAttention';
    import IconAlertSm from '~icons/my-icons/alertsm';
    import Close from '~icons/my-icons/cross';

</script>

<template>
    <div class="fixed top-0 left-0 w-screen min-h-screen h-full overflow-auto bg-grey-900/40 animate-fade-in">
        <!-- <div class="absolute top-0 left-0 w-full h-full"></div> -->
        <div class="w-[800px] bg-grey-50 ml-auto min-h-screen flex flex-col z-[2] relative animate-slide-in">
            
                <div class="px-6 py-5 border-b border-grey-200 bg-white sticky top-0 z-50 flex justify-between ">
                    <h3 
                    class="text-lg font-bold"
                    >Clients needing attention</h3>
                    <Close @click="$router.go(-1)" class="cursor-pointer"/>
                </div>
                
                <div class="border border-grey-200 rounded-lg m-6">
                    <div class="bg-grey-50 px-6 py-2 flex items-center justify-between border-y rounded-t-lg border-grey-200">
                        <p class="text-sm font-bold">Client</p>
                        <p class="text-sm font-bold flex space-x-2 items-center"><IconAlertSm class="h-4 w-4 mr-3 fill-[#FEEBCC]" /> Alerts</p>
                    </div>
                    <div class="px-6 pt-6 pb-4">
                        <template v-if="highlightedAttentionClients && highlightedAttentionClients.length > 0 && !loading">
                            <router-link
                                v-for="(client, clientIndex) in highlightedAttentionClients"
                                :key="clientIndex"
                                :to="{name: 'Admin - Beta clients', query: { viewing: client.clientId || null } }"
                                class="flex justify-between items-center py-3 border-b border-grey-200 group min-h-[52px]"
                            >
                                <div class="space-x-4 flex items-center overflow-hidden">
                                    <div class="shrink-0">
                                        <HappinessRenderer :value="client?.happiness" class="shrink-0" />
                                    </div>
                                    <p class="text-xs font-medium truncate group-hover:underline">{{client?.name}}</p>
                                </div>
                                <p class="text-xs font-medium text-warning w-16 text-center">{{ client?.alerts || "" }}</p>
                            </router-link>
                        </template>
                        <div v-else>
                            <Skeletor v-for="i in 10" height="44" class="my-2" :key="i" />
                        </div>
                    </div>
                </div>

                <div class="px-6 py-3 border-t border-grey-200 bg-white text-right sticky bottom-0 z-[2] mt-auto">
                    <button @click="closeEditClient" type="button" class="mr-6 text-blue-500 font-medium">Cancel</button>
                </div>
        </div>
    </div>
</template>

<script>


export default {
    data: function () {
        return {
            retainerData: [],
            retainersToWatch: [],
            loading: true
        }
    },
    created() {
    },
    async mounted() {
        const auth = getAuth();

        this.retainersToWatch = await RetainersNeedAttention(auth.currentUser.accessToken)

        await this.loadData()

        $('body').addClass('overflow-hidden');
    },
    computed: {
        highlightedAttentionClients() {
            if (this.retainerData) {
                const clientsNeedAttention = this.retainerData.filter(client => {
                    switch (client.happiness) {
                        case "v-sad":
                        case "sad":
                        case "moderate":
                            return true;
                        default:
                            return false;
                    }
                })

                clientsNeedAttention.sort(function(a, b) {
                    return (b.alerts - a.alerts) + (0);
                });

                return clientsNeedAttention
            }
        },
    },
    methods: {
        closeEditClient() {
            $('body').removeClass('overflow-hidden')
            this.$router.go(-1)
        },
        async loadData() {
            const db = getDatabase();

            this.loading = true

            const retainersRef = ref(db, `clients`)

            onValue(retainersRef, async (snapshot) => {

            const data = snapshot.val();

            const clients = Object.entries(data).map(entry => {return {...entry[1], clientId: entry[0]}})

            this.retainerData = clients;

            this.loading = true

            const formattedClients = await Promise.all(clients.map(async (item) => {
                if (!item.retainers) return {...item, alerts: await AlertTotal(item)}

                    const retainersArray = Object.keys(item.retainers).reduce((acc, key) => {
                        if (item.retainers[key]) {
                            const retainer = this.retainersToWatch.find(retainerWatch => retainerWatch.projectID == key);
                            if (retainer) {
                                acc.push({...retainer});
                            }
                        }
                        return acc;
                    }, []);

                    return {...item, retainers: retainersArray, alerts: await AlertTotal({...item, retainers: retainersArray})}
                }));

                this.retainerData = formattedClients.filter(c => !c.archived);
                this.loading = false
                });
            }
        }
    };

</script>


<style lang="scss">

    .tox.tox-tinymce {
        border-color: theme('colors.grey-200');
        border-width: 1px;
        border-radius: 4px;
    }

</style>
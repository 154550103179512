import { getAuth } from "firebase/auth";
import { isProxy, toRaw } from 'vue';
import store from "../store";
import axios from "axios";

export default async function initAllProjects() {

    function getAllProjects() {
        return new Promise(resolve => {
            if(store.getters.getAllProjects.length === 0) {                     
                const auth = getAuth();           
                axios({
                    method: 'get',
                    url: `${import.meta.env.VITE_BASE_API}/project/list?active=true`,
                    headers: {
                        'authorization': auth.currentUser.accessToken
                    }
                }).then(res => {
                    store.commit('updateAllProjects', isProxy(res.data) ? toRaw(res.data): res.data || []);
                    resolve();
                }).catch(err => {
                    console.error('An error occurred while fetching project list:', err);
                });
            } else {
                resolve();
            }
        });
        
    } 

    return getAllProjects().then(res => {
        return res;
    });

}

import store from "../store";
import axios from "axios";

import { getAuth } from "firebase/auth";

export default function projectValueToLabel(value) {
    const auth = getAuth();
    if(store.getters.getAllProjects.length === 0) {
        const currentToken = auth.currentUser?.accessToken;
        axios.get(`${import.meta.env.VITE_BASE_API}/project/list?active=true`,
        {
            headers: {
                'authorization': currentToken
            }
        }
        ).then(res => {
            store.commit('updateAllProjects', res.data);
            return convertValueToLabels(value);
        }).catch(err => {
            console.log(err);
        });
    } else {
        return convertValueToLabels(value);
    }

    function convertValueToLabels(value) {
        const allProjects = store.getters.getAllProjects;
        let thisProject = allProjects.filter(item => {
            return item.id == value
        });
        if (thisProject.length <= 0 || !(store.getters.getUserState.retainer?.find(e => e == thisProject[0].id) || (store.getters.getUserState.permission === 'admin' || store.getters.getUserState.permission === 'webcoda-user'))) {
            return
        }
        if(thisProject.length > 0) {
            return thisProject[0].projectName
        }
        return '';
    }
}
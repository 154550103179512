<script setup>
    import store from "../../store";
    import { getAuth } from "firebase/auth";
    import { getApp } from "firebase/app";
    import { getFunctions, httpsCallable } from "firebase/functions";

    import IconOptions from '~icons/my-icons/options'
    import CheveronDown from '~icons/my-icons/chevron-down'

</script>

<template>
    <div class="relative o-dropdown js-dropdown">
        <template v-if="type == 'adminUsers' || type == 'admin'">
            <div v-if="value == 'view'" class="flex items-center">
                <router-link :to="{ name: (type == 'adminUsers' ? 'Admin Users - User profile' : 'Admin - User profile'), params: { userid: parseUserId }}">
                    <div class="flex border border-gray-200 text-xs items-center rounded-l bg-gray-50 py-1.5 px-4">View</div>
                </router-link>
                <div @click="handleOpenDropdown($event)" class="flex cursor-pointer border aspect-square border-l-0 h-[30px] border-gray-200 items-center rounded-r bg-gray-50 py-2 px-2">
                    <CheveronDown />
                </div>
            </div>
            <button v-else @click="handleOpenDropdown($event)" class="inline-block" type="button">
                <IconOptions/>
            </button>
        </template>
        <template v-if="type == 'client'">
            <div v-if="value == 'view'" class="flex items-center">
                <router-link :to="{ name: 'Client - User profile', params: { clientId: clientId, userid: parseUserId }}">
                    <div class="flex border border-gray-200 text-xs items-center rounded-l bg-gray-50 py-1.5 px-4">View</div>
                </router-link>
                <div @click="handleOpenDropdown($event)" class="flex cursor-pointer border aspect-square border-l-0 h-[30px] border-gray-200 items-center rounded-r bg-gray-50 py-2 px-2">
                    <CheveronDown />
                </div>
            </div>
            <button v-else @click="handleOpenDropdown($event)" class="inline-block" type="button">
                <IconOptions/>
            </button>
        </template>
        <template v-if="type == 'adminUsers' || type == 'admin'">
            <ul
                v-if="options == 'includeRemove'"
                ref="userOptionsDropdown" 
                class="o-dropdown__list min-w-[140px] box-shadow absolute mt-2 top-full right-0 border border-bd rounded text-left z-10"
                :class="disabled ? 'bg-bd' : 'bg-white'"
            >
                <li class="text-red-500 border-b border-bd last:border-none">
                    <button class="py-2 px-3 block text-left font-bold w-full" @click="$emit('handleRemoveUser', parseUserId)" :class="disabled && 'disabled'">Remove</button>
                </li>
                <li v-if="type == 'adminUsers'" class="border-b border-bd last:border-none">
                    <router-link :to="{ name: (type == 'adminUsers' ? 'Admin Users - User profile' : 'Admin - User profile'), params: { userid: parseUserId }}" class="py-2 px-3 block" :class="disabled && 'disabled'">View profile</router-link>
                </li>
                <li v-else class="border-b border-bd last:border-none">
                    <router-link :to="{ name: (type == 'admin' ? 'Admin - User profile' : 'Admin - User profile'), params: { userid: parseUserId }}" class="py-2 px-3 block" :class="disabled && 'disabled'">View profile</router-link>
                </li>
                <li class="border-b border-bd last:border-none">
                    <button class="py-2 px-3 block w-full text-left" @click="$emit('handleCopyEmail')" :class="disabled && 'disabled'">Copy email</button>
                </li>
                <li class="text-red-500" v-if="(isBothAdmin || isSelf) && !deleteHidden">
                    <button class="py-2 px-3 block text-left font-bold w-full" @click="deleteClick" :class="disabled && 'disabled'">Delete account</button>
                </li>
            </ul>
            <ul 
                v-else
                ref="userOptionsDropdown" 
                class="o-dropdown__list min-w-[140px] box-shadow absolute mt-2 top-full right-0 border border-bd rounded text-left z-10"
                :class="disabled ? 'bg-bd' : 'bg-white'"
            >
                <li v-if="type == 'adminUsers'" class="border-b border-bd last:border-none">
                    <router-link :to="{ name: (type == 'adminUsers' ? 'Admin Users - User profile' : 'Admin - User profile'), params: { userid: parseUserId }}" class="py-2 px-3 block" :class="disabled && 'disabled'">View profile</router-link>
                </li>
                <li v-else class="border-b border-bd last:border-none">
                    <router-link :to="{ name: (type == 'admin' ? 'Admin - User profile' : 'Admin - User profile'), params: { userid: parseUserId }}" class="py-2 px-3 block" :class="disabled && 'disabled'">View profile</router-link>
                </li>
                <li class="border-b border-bd last:border-none" v-if="store.getters.getUserState.permission === 'admin' || isSelf">
                    <router-link :to="{ name: 'ChangePassword', params: { userid: parseUserId }}" class="py-2 px-3 block" :class="disabled && 'disabled'">Change password</router-link>
                </li>
                <li class="border-b border-bd last:border-none">
                    <button class="py-2 px-3 block w-full text-left" @click="$emit('handleCopyEmail')" :class="disabled && 'disabled'">Copy email</button>
                </li>
                <li class="border-b border-bd last:border-none" v-if="true">
                    <button class="py-2 px-3 block w-full text-left" @click="$emit('handleResendConfirmation', parseUserId)" :class="disabled && 'disabled'">Resend Confirmation Email</button>
                </li>
                <li class="border-b border-bd last:border-none" v-if="isWebcodaAdmin && !parseUserData.userSettings?.lastLoginTime">
                    <button class="py-2 px-3 block w-full text-left" @click="handleVerifyUser()" :class="disabled && 'disabled'">Verify user</button>
                </li>
                <li class="text-red-500" v-if="(isBothAdmin || isSelf) && !deleteHidden">
                    <button class="py-2 px-3 block text-left font-bold w-full" @click="deleteClick" :class="disabled && 'disabled'">Delete account</button>
                </li>
            </ul>
        </template>
        <template v-if="type == 'client'">
            <ul 
                ref="userOptionsDropdown"
                class="o-dropdown__list min-w-[140px] box-shadow absolute mt-2 top-full right-0 border border-bd rounded text-left z-10"
                :class="disabled ? 'bg-bd' : 'bg-white'"
            >
                <li class="border-b border-bd last:border-none">
                    <router-link :to="{ name: 'Client - User profile', params: { clientId: clientId, userid: parseUserId }}" class="py-2 px-3 block" :class="disabled && 'disabled'">View profile</router-link>
                </li>
                <li class="border-b border-bd last:border-none" v-if="store.getters.getUserState.permission === 'admin' || isSelf">
                    <router-link :to="{ name: 'ChangePassword', params: { userid: parseUserId }}" class="py-2 px-3 block" :class="disabled && 'disabled'">Change password</router-link>
                </li>
                <!-- <li class="border-b border-bd last:border-none" v-if="true">
                    <button class="py-2 px-3 block w-full text-left" @click="$emit('handleResendConfirmation', parseUserId)" :class="disabled && 'disabled'">Resend Confirmation Email</button>
                </li> -->
                <li class="border-b border-bd last:border-none">
                    <button class="py-2 px-3 block w-full text-left" @click="$emit('handleCopyEmail')" :class="disabled && 'disabled'">Copy email</button>
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                parseUserData: this.userData,
                parseUserId: this.userId || this.userData.uid,
                authUserUID: '',
                deleteHidden: this.hideDelete ? this.hideDelete : false,
                hideResendConfirmation : this.hideResendEmail ? true : false,
            }
        },
        props: ['userData', 'userId', 'hideDelete', 'hideResendEmail', 'disabled', 'type', 'value', 'options','clientId'],
        created() {
        },
        mounted() {
            const auth = getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {
                    this.authUserUID = user.uid;
                }
            });
            document.addEventListener('click', event => {
                if(event.target.closest('.js-dropdown') === null) {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                }
            });
        },
        computed: {
            isWebcodaAdmin() {
                const currentUser = store.getters.getUserState;
                const viewAsClient = store.getters.getViewAsClient;
                if(viewAsClient) {
                    return false;
                }
                return currentUser.permission === 'admin'
            },
            devMode() {
                return this.$root.env === "development"
            },
            isSelf() {
                return this.authUserUID === this.parseUserId
            },
            isBothAdmin() {
                const currentUser = store.getters.getUserState;
                const viewAsClient = store.getters.getViewAsClient;
                if(viewAsClient) {
                    return false;
                }
                return currentUser.permission === 'admin' || currentUser.permission === 'client-admin';
            },
            showResendConfirmButton() {
                if(this.enableResendConfirmation) {
                    return true;
                } else {
                    if(this.parseUserData.userSettings?.isVerified || this.parseUserData.userSettings?.lastLoginTime) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        },
        methods: {
            handleVerifyUser() {
                const { parseUserId } = this;
                const app = getApp();
                const functions = getFunctions(app);
                // connectFunctionsEmulator(functions, "localhost", 5001);
                const verifyUser = httpsCallable(functions, "verifyUser");
                verifyUser({ uid: parseUserId, data: { uid: parseUserId } }).then(result => {
                    alert('User now verified');
                }).catch(err => {
                    alert('Error');
                    console.log(err)
                });
            },
            deleteClick(e) {
                e.target.closest('.js-dropdown').classList.remove('is-active');
                this.$emit('handleDeleteModal', this.userData);
            },
            handleOpenDropdown(e) {
                let targetClassList = e.target.closest('.js-dropdown').classList;
                if(targetClassList.contains('is-active')) {
                    targetClassList.remove('is-active')
                } else {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                    targetClassList.add('is-active')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .o-dropdown {
        &.is-active {
            .o-dropdown__list {
                display: block;
            }
        }
        &__list {
            display: none;
        }
    }
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

</style>
<script setup>

	import { getAuth } from "firebase/auth";
	import { getDatabase, ref, onValue } from "firebase/database";
	import axios from 'axios';

	import store from '@/store';
	import initCurrentUserSession from '@/helpers/InitCurrentUserSession';
	import initAllProjects from '@/helpers/InitAllProjects';
	import ProjectValueToLabel from '@/helpers/ProjectValueToLabel';
	import PermissionsHelpers from '@/helpers/PermissionHelpers';

	import ReportsIcon from '~icons/my-icons/reports';
	import GridView from '~icons/my-icons/grid-view';
	import BarChart from '~icons/my-icons/bar-chart';
	

	import yearMonthFormat from '@/helpers/YearMonthFormat';

	import servicesData from '@/data/servicesData.js';

	import LogAuditLog from "@/helpers/logAuditLog";
</script>

<template>
	<div>
		<div class="inline-flex w-full items-center justify-between">
			<h3 class="text-3xl font-bold pt-12 pb-12">Retainer Report</h3>
			<div class="flex items-center">
				<div class="border bd-gray-200 flex rounded-[4px] overflow-hidden mr-6">
					<router-link
						class="bg-white"
						exact-active-class="bg-purple-900 parent-active"
						:to="{ name: 'Beta Dashboard Reports Overview' }"
					>
							<GridView class="my-2 mx-3 parent-active:fill-white"  />
					</router-link>
					<router-link 
						class="bg-white"
						exact-active-class="bg-purple-900 parent-active"
						:to="{ name: 'Beta Dashboard Reports Graph' }"
					>
						<BarChart class="my-2 mx-3 parent-active:fill-white" />
					</router-link>
				</div>
				<router-link v-if="$route.params.retainerId" :to="{ name: 'Beta Dashboard Reports Listing', params: { date: yearMonthFormat(mostCurrentApprovedMonth) }}" class="inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
					<ReportsIcon class="mr-3 text-white" />
					View latest report
				</router-link>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeTab: 'reports',
				activeRetainer: "",
				assignedRetainers: [],
				allRetainersList: [],
				deleteModalActive: false,
				deleteSelectedUser: {},
				editMode: false,
				editField: "",
				editIsUpdated: false,
				editRetainerData: {},
				notifications: {},
				tileData: [],
				retainerData: null,
				freshdeskStats: [],
				snapshotLoading: true,
				retainerUsers: [],
				internalUsers: [],
				uploading: false,
				isInitLoading: true,
				currentUserRole: store.getters.getUserState.permission,
				activeServiceModalId: null,
				accountManagerModalActive: false,
				retainerChangelogs: null,
				isLoading: false,
			}
		},
		computed: {
			internalWebcodaUserRole() {
				return this.currentUserRole === 'admin' || this.currentUserRole === 'webcoda-user'
			},
			activeRetainerLabel() {
				return ProjectValueToLabel(this.$route.params.retainerId)
			},
			isWebcodaAdmin() {
				const currentUser = store.getters.getUserState;
				const viewAsClient = store.getters.getViewAsClient;
				if(viewAsClient) {
					return false
				}
				return currentUser.permission === 'admin'
			},
			filterSnapshotMonths() {
				let { tileData } = this;
				
				// Filter out by tasks count
				return tileData.filter(item => item.listData.filter(l => l.itemName === 'Tasks')[0].itemValue > 0);
			},
			mostCurrentApprovedMonth() {
				const filteredData = this.filterSnapshotMonths;
				if(filteredData && filteredData.length > 0) {
					filteredData.sort((a, b) => {
						return new Date(b.title) - new Date(a.title) 
					});
					return filteredData[0].title;
				}
				return '';
			},
			formatRetainerUrls() {
				if(this.retainerData?.url) {
					const urlArr = this.retainerData?.url?.replace(/\r?\n|\r/gm, '').split(',');
					return urlArr.filter(n => /\S/.test(n));
				}
				return []
			},
			activeServiceModal() {
				const serviceId = this.activeServiceModalId;
				const { formatServicesData } = this;
				return formatServicesData.filter(item => item.serviceId === serviceId).length > 0 ? formatServicesData.filter(item => item.serviceId === serviceId)[0] : null;
			},
			formatServicesData() {
				const serviceData = servicesData;
				const { retainerData } = this;
				const retainerServicesData = retainerData.services;
				const defaultServicesRates = store.getters.getAppSettings.services;

				const formattedData = serviceData?.map(item => {
					let isServiceActive = false;
					item.cost = defaultServicesRates[item.serviceId];
					item.hours = 0;
					for(const service in retainerServicesData) {
						if(item.serviceId === service) {
							isServiceActive = retainerServicesData[service].hours && Number(retainerServicesData[service].hours) > 0 ? true : false;
							item.cost = retainerServicesData[service] ? retainerServicesData[service].cost : defaultServicesRates[service];
							item.hours = retainerServicesData[service] && retainerServicesData[service].hours ? retainerServicesData[service].hours : 0;
							item.occurance = retainerServicesData[service].occurance ? retainerServicesData[service].occurance : '';
							retainerServicesData[service].date ? item.date = retainerServicesData[service].date : '';
						} 
					}
					return {
						...item,
						activeService: isServiceActive
					}
				});
				return formattedData;
			}
		},
		async mounted() {

			await initCurrentUserSession(this);

			if((this.internalWebcodaUserRole || store.getters.getUserState.retainer?.length > 1) || store.getters.getViewAsClient) {
				await initAllProjects();
				const retainers = store.getters.getAllProjects.map(item => {
					return {
						label: item.projectName.trim(" "),
						value: item.id
					}
				});
				function sortedArray() {
					function compare(a, b) {
						if (a.label < b.label)
							return -1;
						if (a.label > b.label)
							return 1;
						return 0;
					}
					return retainers.sort(compare);
				}				

				if(this.internalWebcodaUserRole || store.getters.getViewAsClient) {
					this.allRetainersList = sortedArray();
				} else {
					this.assignedRetainers = sortedArray().filter(item => store.getters.getUserState.retainer?.includes(item.value))
				}

			}

			if(this.$route.params.retainerId) {
				this.getRetainerData();
				this.getStatistics();
				this.getRetainerChangelogs();
			} else {
				this.tileData = [];
				this.snapshotLoading = false;
			}

		},
		methods: {
			getRetainerChangelogs() {
				const userRetainer = this.$route.params.retainerId;
				const db = getDatabase();
				const retainerHistoryRef = ref(db, `/retainerHistory/${userRetainer}`);
				onValue(retainerHistoryRef, snapshot => {
					if(snapshot.val()) {
						const res = snapshot.val();
						const arr = [];
						for(const month in res) {
							var obj = {
								title: this.dateLabel(`${month}-1`)
							};
							var changesArr = [];
							for(const change in res[month]) {
								changesArr.push(res[month][change])
							}
							obj.changes = changesArr;
							arr.push(obj)
						}
						this.retainerChangelogs = arr;
					}
				});
			},
			getRetainerData() {
				
				let userRetainer = this.$route.params.retainerId;

				const db = getDatabase();
				const retainersRef = ref(db, `/retainers/${userRetainer}`);
				const retainerUsersRef = ref(db, `/retainerUsers/${userRetainer}/`);

				onValue(retainersRef, snapshot => {
					this.isInitLoading = true;
					this.retainerData = snapshot.val() ? snapshot.val() : {};
					if (this.retainerData.plans != null && this.retainerData.plans.length > 0) {
						var lastPlan = this.retainerData.plans[this.retainerData.plans.length - 1];
						this.retainerData.monthlyHours = lastPlan.monthlyHours;
                        this.retainerData.rolloverHours = lastPlan.rolloverHours;
                        this.retainerData.rolloverFrom = lastPlan.rolloverFrom;
                    }

					this.editRetainerData = snapshot.val() ? snapshot.val() : {};
					store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
					this.isInitLoading = false;
				});
				
				onValue(retainerUsersRef, snapshot => {
					if(snapshot.val()) {
						let formattedData = Object.keys(snapshot.val()).map((key) => {
							return {
								uid: key,
								...snapshot.val()[key]
							}
						});
						let clientUserArr = formattedData.filter(user => {
							return user.permission !== 'admin' && user.permission !== 'webcoda-user'
						});
						clientUserArr.forEach((item, index) => {
							if(item.permission === 'client-admin') {
								clientUserArr.splice(index, 1);
								clientUserArr.unshift(item);
							}
						});
						let webcodaUserArr = formattedData.filter(user => {
							return user.permission == 'admin' || user.permission == 'webcoda-user'
						});
						this.internalUsers = webcodaUserArr.map(user => {
							return {
								uid: user.uid,
								userSettings: {...user}
							}
						});
						this.retainerUsers = clientUserArr.map(user => {
							return {
								uid: user.uid,
								userSettings: {...user}
							}
						});
					} else {
						this.retainerUsers = [];
					}
				});
			},
			syncProjects(){

				var filter = [];
				var today = new Date();
				var d;
				
				for(var i = 11; i >= 0; i -= 1) {
					d = new Date(today.getFullYear(), today.getMonth() - i, 1);
					filter.push({
						month: d.getMonth() + 1,
						year: d.getFullYear()
					});
				}
				
				const auth = getAuth();
				auth.onAuthStateChanged(user => {
					if(user) {
						const currentToken = auth.currentUser.accessToken;					
						
						axios.get(`${this.$root.apiBaseUrl}/import/ImportProjects`, {
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							},							
						}).then(res => {
							this.freshdeskStats = res.data;													
						}).catch(err => {
							console.log(err)							
						});

						
                        axios.get(`${this.$root.apiBaseUrl}/retainer/sync-rollover/${this.$route.params.retainerId}`, {
                            headers: {
                                'authorization': currentToken,
                                'timezone': this.$root.apiTimezone
                            },
                        }).then(res => {
														LogAuditLog({
																type: "Retainer",
																object: "Sync",
																action: "run",
																data: {
																		user: user.uid,
																		retainerId: this.$route.params.retainerId,
																}
														})

                            axios({
                                method: 'post',
                                url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
                                data: filter,
                                headers: {
                                    'authorization': currentToken,
                                    'timezone': this.$root.apiTimezone
                                },
																params: {
																	clientView: PermissionsHelpers.isWebcodaAdmin() ? 'false' : 'true'
																}
                            }).then(res => {
                                this.tileData = res.data;
                                this.snapshotLoading = false;
                                this.notifications = 'SuccessSync';
                                setTimeout(() => {
                                    this.notifications = '';
                                }, 3600)
                            }).catch(err => {
                                console.log(err);
                            });

                        }).catch(err => {
                            console.log(err)
                        });

					}});
				

			},
			getStatistics(){
				var filter = [];
				var today = new Date();
				var d;
				
				for(var i = 11; i >= 0; i -= 1) {
					d = new Date(today.getFullYear(), today.getMonth() - i, 1);
					filter.push({
						month: d.getMonth() + 1,
						year: d.getFullYear()
					});
				}

				
				const auth = getAuth();
				auth.onAuthStateChanged(user => {
					if(user) {
						const currentToken = auth.currentUser.accessToken;					
						
						axios.get(`${this.$root.apiBaseUrl}/freshdesk/${this.$route.params.retainerId}`, {
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							},							
						}).then(res => {
							this.freshdeskStats = res.data;													
						}).catch(err => {
							console.log(err)							
						});

						axios({
							method: 'post',
							url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
							data: filter,
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							},
							params: {
								clientView: PermissionsHelpers.isWebcodaAdmin() ? 'false' : 'true'
							}
						}).then(res => {
							this.tileData = res.data;	
							this.snapshotLoading = false;				
						}).catch(err => {
							console.log(err);
						});

					}});
				
			},
			handleUpdateActiveRetainer(retainerId, notHandler) {
				this.snapshotLoading = true;

				if(!notHandler) {
					this.$router.replace({params: {retainerId: retainerId}});
				}

				this.internalUsers = [];
				this.getRetainerData();
				this.getStatistics();
				this.getRetainerChangelogs();
			},
			handleCopyEmail(email) {
                if(email) {
                    navigator.clipboard.writeText(email).then(() => {
						this.notifications = 'copyEmail';
						setTimeout(() => {
                            this.notifications = '';
                        }, 3600)
                    }, function(err) {
                        console.error('Async: Could not copy text: ', err);
                    });
                }
            },
            handleDeleteUserModal(uid, user) {
                this.deleteModalActive = true;
                this.deleteSelectedUser.userId = uid;
                this.deleteSelectedUser.user = user;
                document.body.classList.add('overflow-hidden');
            },
			handleDeleteModalClose(success) {
                this.deleteModalActive = false;
                this.deleteSelectedUser = {};
                if(success) {
                    this.notifications = 'deleteUser';
                }
            },
			sendConfirmationMail(user) {
                
                const auth = getAuth();
                auth.onAuthStateChanged(currentUser => {
                    if(currentUser) {

                        const currentToken = auth.currentUser.accessToken;
                        const currentUrlBase = window.location.origin;
                        
                        let data = {
                            Uid: user.uid,
                            SiteUrl: currentUrlBase                            
                        }

                        this.isLoading = true;

                        axios.post(`${this.$root.apiBaseUrl}/account/confirmation`, data, {
                            headers: {
                                'authorization': currentToken,
                                'timezone': this.$root.apiTimezone
                            }
                        }).then(res => {
                            // alert('sent');  
                            this.isLoading = false;
                            this.notifications = 'ResendConfirmation';  
														setTimeout(() => {
																this.notifications = '';
														}, 3600)                      
                        }).catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });

                    }
                });
            }, 
			handleServiceModalClose(success) {
				this.activeServiceModalId = null;
			},
			handleAccountManagerModalClose(success) {
				this.accountManagerModalActive = false;
                if(success) {
                    this.notifications = '';
                    this.notifications = 'Success';
                }
			},
			dateLabel(dateStr) {
				var options = { month: 'long', year: 'numeric' };		
				var thisDate = new Date(dateStr);
				return thisDate.toLocaleString('en-GB', options);
			},
		}
	}
</script>

<style lang="scss">
	.multiselect {
		$this: &;
		&--retainerToggle {
			white-space: nowrap;
			min-height: 60px;
			#{$this}-search {
				font-size: 1.5rem;
			}
			#{$this}-single-label {
				margin-left: 3px;
			}
			#{$this}-dropdown {
				height: 30rem;
				border-right: 0;
			}
			#{$this}-options {
				display: block !important;
			}
			#{$this}-option {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
</style>
